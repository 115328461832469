<script>
export default {
  name: "EditTag",
  props: {
    value: {
      type: Array,
      default: () => []
    },
    inputMaxlength: {
      type: Number,
      default: 6
    },
    tagMaxlength: {
      type: Number,
      default: 999
    },
    placeholder: {
      type: String,
      default: "Add a tag"
    },
    inputWidth: {
      type: String,
      default: "100%"
    }
  },
  data() {
    return {
      addTag: "",
      dynamicTags: this.value
    };
  },
  methods: {
    handleClose(tag) {
      this.dynamicTags = this.value;
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
      this.$emit("input", this.dynamicTags);
    },
    addTags() {
      this.dynamicTags = this.value;
      if (this.addTag.trim() === "") {
        return;
      }
      if (this.dynamicTags.includes(this.addTag)) {
        return;
      }
      if (this.dynamicTags.length >= this.tagMaxlength) {
        this.$message.error("最多只能添加" + this.tagMaxlength + "个标签");
        return;
      }
      this.dynamicTags.push(this.addTag);
      this.addTag = "";
      this.$emit("input", this.dynamicTags);
    }
  }
}
</script>

<template>
  <div class="edit-tag">
    <div class="tag-container clearfix">
      <div class="tag-item fl">
        <el-input
            class="input-new-tag"
            :placeholder="placeholder"
            :maxlength="inputMaxlength"
            :style="{width: inputWidth}"
            v-model="addTag"
            ref="saveTagInput"
        >
        </el-input>
      </div>
      <div class="tag-item fl">
        <span class="add-tag-btn ml-10" @click="addTags">
          添加
        </span>
      </div>
    </div>
    <div class="tag-list" v-if="value.length > 0">
      <span class="label">已添加</span>
      <el-tag
          class="tag-item ml-10 mt-18"
          :key="tag"
          v-for="tag in value"
          closable
          :disable-transitions="false"
          @close="handleClose(tag)">
        {{tag}}
      </el-tag>
    </div>
  </div>
</template>

<style lang="scss" scoped src="@/styles/common.scss"></style>
<style scoped lang="scss">
.add-tag-btn {
  background: #3984F5;
  color: #fff;
  border-radius: 4px;
  display: inline-block;
  width: 80px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background: lighten(#3984F5, 5%);
  }
}
</style>