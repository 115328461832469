<script>
export default {
  name: "data-table",
  props: {
    data: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    headerWidth: {
      type: String,
      default: '140px'
    },
    cellWidth: {
      type: String,
      default: '180px'
    },
    cellHeight: {
      type: String,
      default: '60px'
    },
    tableHeight: {
      type: String,
      default: '200px'
    },
    cellAutoWidth: {
      type: Boolean,
      default: true
    }
  },
}
</script>

<template>
<div class="table-container" :style="{'--header-width': headerWidth, '--cell-width': cellWidth, '--cell-height': cellHeight, '--table-height': tableHeight, '--cell-auto-width': cellAutoWidth}">
  <div class="table-body">
    <div class="table-row">
      <template v-for="(column, cIndex) in columns">
        <div class="table-cell table-header" :key="cIndex">
          <slot name="table-header" :column="column" :label="column.label" :cIndex="0" :rIndex="cIndex">
            {{ column.label }}
          </slot>
        </div>
      </template>
    </div>
    <div class="table-row" v-for="(item, index) in data" :key="index">
        <div class="table-cell" :class="[cellAutoWidth ? '' : 'cell-fixed-width']" v-for="(column, cIndex) in columns" :key="cIndex">
          <slot name="table-cell" :row="item" :$index="index" :cIndex="cIndex" :rIndex="index" :prop="column.prop"
                :column="column" :$isFocused="false"
                :value="item[column.prop]">{{ item[column.prop] }}
          </slot>
        </div>
        <div class="row-suffix">
          <slot name="row-suffix" :row="item" :$index="index"></slot>
        </div>
    </div>
  </div>
  <div class="table-footer">
    <slot name="table-suffix"></slot>
  </div>
</div>
</template>

<style scoped lang="scss">
.table-container {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  display: table;
  border-collapse: collapse;

  .table-body, .table-footer {
    box-sizing: border-box;

    .table-row {
      display: table-row;

      .table-cell {
        box-sizing: border-box;
        display: table-cell;
        font-weight: 400;
        font-size: 12px;
        color: #444F57;
        line-height: 36px;
        padding: 11.5px 15px;
        border: 1px solid #DBE1E5;
        min-width: var(--cell-width);
        height: var(--cell-height);

        &.cell-fixed-width {
          width: calc(var(--cell-width) + 1px);
        }

        &.table-header {
          background: #F6F7F9;
        }
      }
    }
  }
}
</style>