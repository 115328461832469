<script>
import HorizontalTable
  from "@/views/system/organization/dataReporting/components/common/horizontal-table/HorizontalTable.vue";
import HorizontalTableYear
  from "@/views/system/organization/dataReporting/components/common/horizontal-table/HorizontalTableYear.vue";

const thisYear = new Date().getFullYear();

function getYearData(year, template, num = 3) {
  if (typeof year === "string" && year !== "") {
    year = parseInt(year)
  }
  let data = [];
  for (let i = 0; i < num; i++) {
    let item = {
      year: year ? year - i : "",
      data: template
    }

    data.push(item)
  }
  return data
}

const DEFAULT_TEMPLATE = [
  {
    month: "1月",
    money: null
  },
  {
    month: "2月",
    money: null
  },
  {
    month: "3月",
    money: null
  },
  {
    month: "4月",
    money: null
  },
  {
    month: "5月",
    money: null
  },
  {
    month: "6月",
    money: null
  },
  {
    month: "7月",
    money: null
  },
  {
    month: "8月",
    money: null
  },
  {
    month: "9月",
    money: null
  },
  {
    month: "10月",
    money: null
  },
  {
    month: "11月",
    money: null
  },
  {
    month: "12月",
    money: null
  }
]

const DEFAULT_FORM = {
  xmjsqk: {
    tjsj: {
      zftz: null,
      shtz: null
    },
    jsqk: [
      {
        industry: "已投产",
        number: null
      },
      {
        industry: "开工建设",
        number: null
      },
      {
        industry: "前期手续",
        number: null
      },
      {
        industry: "临时建设",
        number: null
      },
      {
        industry: "正式建设",
        number: null
      },
      {
        industry: "竣工项目",
        number: null
      },
      {
        industry: "暂未启动",
        number: null
      }
    ],
  },
  xmzl: {
    yjsl: null,
    cqsl: null,
  },
  qqsxxm: [
    {
      type: "工商注册",
      number: null
    },
    {
      type: "营业执照",
      number: null
    },
    {
      type: "银行开户",
      number: null
    },
    {
      type: "税务登记",
      number: null
    },
    {
      type: "发改委备案",
      number: null
    },
    {
      type: "建设用地规划红线图",
      number: null
    },
    {
      type: "用地规划许可证",
      number: null
    },
    {
      type: "投资备案证",
      number: null
    },
    {
      type: "工程规划许可证",
      number: null
    },
    {
      type: "施工图设计",
      number: null
    },
    {
      type: "施工图审查备案",
      number: null
    },
    {
      type: "施工许可证",
      number: null
    }
  ],
  zdxmqk: {
    shtzxm: {
      number: null,
      money: null
    },
    zftzxm: {
      number: null,
      money: null
    }
  },
  yqqyqk: {
    tjsj: {
      gdzcjhtz: null,
      gytzjhtz: null,
    },
    gdzcfbqk: getYearData(thisYear, JSON.parse(JSON.stringify(DEFAULT_TEMPLATE))),
    gytzfbqk: getYearData(thisYear, JSON.parse(JSON.stringify(DEFAULT_TEMPLATE)))
  }
}

export default {
  name: "ProjectManagement", // 项目管理
  inject: ["updateEditStatus"], // 注入updateEditStatus编辑状态，用来判断是否提示保存 0：未编辑，1：编辑中，2：已保存方法
  components: {
    HorizontalTable,
    HorizontalTableYear
  },
  data() {
    return {
      id: null,
      loading: false,
      // 是否重置表单
      isReset: false,
      isEdit: false,
      form: JSON.parse(JSON.stringify(DEFAULT_FORM)),
      tableColumns: {
        jsqk: [
          {
            label: "数量/行业",
            prop: "industry",
          },
          {
            label: "项目数量（个）",
            prop: "number",
          }
        ],
        qqsxxm: [
          {
            label: "类型",
            prop: "type",
          },
          {
            label: "项目数量（个）",
            prop: "number",
          }
        ],
        gdzcfbqk: [
          {
            label: "金额/时间",
            prop: "month",
          },
          {
            label: "投资金额（亿元）",
            prop: "money",
          }
        ],
        gytzfbqk: [
          {
            label: "金额/时间",
            prop: "month",
          },
          {
            label: "投资金额（亿元）",
            prop: "money",
          }
        ]
      }
    }
  },
  watch: {
    form: {
      handler(val, oldVal) {
        if (!this.isReset && !this.isEdit) {
          this.updateEditStatus(1)
        } else if (this.isReset) {
          this.isReset = false;
          this.updateEditStatus(0)
        } else if (this.isEdit) {
          this.isEdit = false;
          this.updateEditStatus(2)
        }
      },
      deep: true
    }
  },
  mounted() {
    this.getDetail('1-4')
  },
  methods: {
    getDetail(step) {
      const data = {
        organize_id: this.$route.query.id,
        first_level: step.split('-')[0],
        second_level: step.split('-')[1]
      }
      this.loading = true;
      this.$api.dataReportingDetail(data).then(res => {
        this.loading = false;
        this.id = res.data.id || null;
        this.isReset = true;
        this.loading = false;
        if (res.data.content) {
          this.form = JSON.parse(res.data.content)
        }
      })
    },
    save() {
      const params = {
        id: this.id,
        organize_id: this.$route.query.id,
        first_level: 1,
        second_level: 4,
        content: JSON.stringify(this.form)
      }
      this.$api.saveDataPark(params).then(res => {
        this.id = res.data.id;
        this.form = JSON.parse(JSON.parse(res.data.content));
        this.isEdit = true;
        this.$message.success('保存成功')
        this.updateEditStatus(2)
      })
    },
    handleNumericInput(obj, prop, event) {
      if (obj && prop in obj) {
        obj[prop] = event.replace(/[^\d]/g, '');
      }
    },
    // 重置表单
    resetForm() {
      this.$confirm('确定清空当前页面的数据内容吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.form = JSON.parse(JSON.stringify(DEFAULT_FORM))
        this.isReset = true
      })
    },
    addRow(type) {
      if (type === 'gdzcfbqk') {
        console.log(getYearData('', JSON.parse(JSON.stringify(DEFAULT_TEMPLATE))), 'gdzcfbqk')
        this.form.yqqyqk.gdzcfbqk.push(getYearData('', JSON.parse(JSON.stringify(DEFAULT_TEMPLATE)), 1)[0])
      } else if (type === 'gytzfbqk') {
        this.form.yqqyqk.gytzfbqk.push(getYearData('', JSON.parse(JSON.stringify(DEFAULT_TEMPLATE)), 1)[0])
      }
    }
  }
}
</script>

<template>
  <div class="overview-park pt-10 pb-20" v-loading="loading">
    <div class="item pt-20 pb-20">
      <h3 class="headline">一、项目建设情况</h3>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">1、统计数据</div>
        <div class="content">
          <div class="item-row mb-16">
            <span class="label">政府投资</span>
            <el-input v-model="form.xmjsqk.tjsj.zftz" placeholder="请输入"
                      @input="event => handleNumericInput(form.xmjsqk.tjsj, 'zftz', event)"
                      style="width: 340px;"></el-input>
            <span class="unit">个</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">社会投资</span>
            <el-input v-model="form.xmjsqk.tjsj.shtz" placeholder="请输入"
                      @input="event => handleNumericInput(form.xmjsqk.tjsj, 'shtz', event)"
                      style="width: 340px;"></el-input>
            <span class="unit">个</span>
          </div>
        </div>
      </div>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">2、不同类型的项目建设情况<span class="tips">（建议不超过7个类型）</span></div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.xmjsqk.jsqk" :columns="tableColumns.jsqk">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'industry'">
                  <div class="row-title pointer clearfix" v-if="scope.row.industry !== '' && !scope.row.$isFocused" @dblclick="$set(scope.row, '$isFocused', true)">
                    {{ scope.row.industry }} <i class="fr icon-delete icon17 pointer"
                                                @click="form.xmjsqk.jsqk.splice(scope.$index, 1)"></i></div>
                  <el-input v-else v-model="scope.row.industry" placeholder="请输入建设类型"
                            @focus="$set(scope.row, '$isFocused', true)" @blur="$set(scope.row, '$isFocused', false)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'number'">
                  <el-input v-model="scope.row.number" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'number', event)"></el-input>
                </template>
              </template>
              <div class="table-add" slot="suffix">
                <i class="icon-add icon17 pointer"
                   @click="form.xmjsqk.jsqk.push({industry: '', number: null})"></i>
              </div>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">二、项目总览</h3>
      <div class="item-content pl-25">
        <div class="content pt-20">
          <div class="item-row mb-16">
            <span class="label">预警数量</span>
            <el-input v-model="form.xmzl.yjsl" placeholder="请输入"
                      @input="event => handleNumericInput(form.xmzl, 'yjsl', event)" style="width: 340px;"></el-input>
            <span class="unit">个</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">超期数量</span>
            <el-input v-model="form.xmzl.cqsl" placeholder="请输入"
                      @input="event => handleNumericInput(form.xmzl, 'cqsl', event)" style="width: 340px;"></el-input>
            <span class="unit">个</span>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">三、前期手续项目</h3>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">前期手续项目<span class="tips">（建议添加8-15个类型）</span></div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.qqsxxm" :columns="tableColumns.qqsxxm">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'type'">
                  <div class="row-title pointer clearfix" v-if="scope.row.type !== '' && !scope.row.$isFocused" @dblclick="$set(scope.row, '$isFocused', true)">
                    {{ scope.row.type }} <i class="fr icon-delete icon17 pointer"
                                            @click="form.qqsxxm.splice(scope.$index, 1)"></i></div>
                  <el-input v-else v-model="scope.row.type" placeholder="请输入手续类型"
                            @focus="$set(scope.row, '$isFocused', true)" @blur="$set(scope.row, '$isFocused', false)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'number'">
                  <el-input v-model="scope.row.number" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'number', event)"></el-input>
                </template>
              </template>
              <div class="table-add" slot="suffix">
                <i class="icon-add icon17 pointer"
                   @click="form.qqsxxm.push({type: '', number: null})"></i>
              </div>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">四、重点项目情况</h3>
      <div class="item-content pl-25">
        <div class="content col-2 pt-20">
          <div class="item-row mb-16">
            <span class="label">社会投资项目</span>
            <el-input v-model="form.zdxmqk.shtzxm.number" placeholder="请输入"
                      @input="event => handleNumericInput(form.zdxmqk.shtzxm, 'number', event)"
                      style="width: 340px;"></el-input>
            <span class="unit">个</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">计划投资金额</span>
            <el-input v-model="form.zdxmqk.shtzxm.money" placeholder="请输入"
                      @input="event => handleNumericInput(form.zdxmqk.shtzxm, 'money', event)"
                      style="width: 340px;"></el-input>
            <span class="unit">亿元</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">政府投资项目</span>
            <el-input v-model="form.zdxmqk.zftzxm.number" placeholder="请输入"
                      @input="event => handleNumericInput(form.zdxmqk.zftzxm, 'number', event)"
                      style="width: 340px;"></el-input>
            <span class="unit">个</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">计划投资金额</span>
            <el-input v-model="form.zdxmqk.zftzxm.money" placeholder="请输入"
                      @input="event => handleNumericInput(form.zdxmqk.zftzxm, 'money', event)"
                      style="width: 340px;"></el-input>
            <span class="unit">亿元</span>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">五、园区企业情况</h3>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">1、统计数据</div>
        <div class="content">
          <div class="item-row mb-16">
            <span class="label">固定资产计划投资</span>
            <el-input v-model="form.yqqyqk.tjsj.gdzcjhtz" placeholder="请输入"
                      @input="event => handleNumericInput(form.yqqyqk.tjsj, 'gdzcjhtz', event)"
                      style="width: 340px;"></el-input>
            <span class="unit">亿元</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">工业投资计划投资</span>
            <el-input v-model="form.yqqyqk.tjsj.gytzjhtz" placeholder="请输入"
                      @input="event => handleNumericInput(form.yqqyqk.tjsj, 'gytzjhtz', event)"
                      style="width: 340px;"></el-input>
            <span class="unit">亿元</span>
          </div>
        </div>
      </div>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">2、固定资产分布情况</div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table-year :data="form.yqqyqk.gdzcfbqk" :columns="tableColumns.gdzcfbqk">
              <template #table-year-cell="scope">
                <div class="row-year pointer" v-if="scope.row.year !== '' && !scope.row.$isFocused" @dblclick="$set(scope.row, '$isFocused', true)">
                  {{ scope.row.year }}年
                </div>
                <div class="row-year" v-else>
                  <el-input v-model="scope.row.year" placeholder="请输入年份"
                            @focus="$set(scope.row, '$isFocused', true)" @blur="$set(scope.row, '$isFocused', false)"
                            @input="event => handleNumericInput(scope.row, 'year', event)"
                  ></el-input>
                </div>
              </template>
              <template #table-cell="scope">
                <template v-if="scope.prop === 'month'">
                  <div class="row-title">
                    {{ scope.row.month }}
                  </div>
                </template>
                <template v-else-if="scope.prop === 'money'">
                  <el-input v-model="scope.row.money" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'money', event)"></el-input>
                </template>
              </template>
              <template #row-suffix="scope">
                <div class="table-delete">
                  <i class="fr icon-delete icon17 pointer"
                     @click="form.yqqyqk.gdzcfbqk.splice(scope.$index, 1)"></i>
                </div>
              </template>
              <div class="table-row-add pt-20" slot="table-suffix">
                <span class="add-btn" @click="addRow('gdzcfbqk')"><i class="icon-add icon17 pointer mr-5"></i>新增一行</span>
              </div>
            </horizontal-table-year>
          </div>
        </div>
      </div>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">3、工业投资分布情况</div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table-year :data="form.yqqyqk.gytzfbqk" :columns="tableColumns.gytzfbqk">
              <template #table-year-cell="scope">
                <div class="row-year pointer" v-if="scope.row.year !== '' && !scope.row.$isFocused" @dblclick="$set(scope.row, '$isFocused', true)">
                  {{ scope.row.year }}年
                </div>
                <div class="row-year" v-else>
                  <el-input v-model="scope.row.year" placeholder="请输入年份"
                            @focus="$set(scope.row, '$isFocused', true)" @blur="$set(scope.row, '$isFocused', false)"
                            @input="event => handleNumericInput(scope.row, 'year', event)"
                  ></el-input>
                </div>
              </template>
              <template #table-cell="scope">
                <template v-if="scope.prop === 'month'">
                  <div class="row-title">
                    {{ scope.row.month }}
                  </div>
                </template>
                <template v-else-if="scope.prop === 'money'">
                  <el-input v-model="scope.row.money" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'money', event)"></el-input>
                </template>
              </template>
              <template #row-suffix="scope">
                <div class="table-delete">
                  <i class="fr icon-delete icon17 pointer"
                     @click="form.yqqyqk.gytzfbqk.splice(scope.$index, 1)"></i>
                </div>
              </template>
              <div class="table-row-add pt-20" slot="table-suffix">
                <span class="add-btn" @click="addRow('gytzfbqk')"><i class="icon-add icon17 pointer mr-5"></i>新增一行</span>
              </div>
            </horizontal-table-year>
          </div>
        </div>
      </div>
    </div>
    <div class="item pb-20 pl-25 btn_box">
      <span class="btn blue" @click="save">确定</span>
      <span class="btn" @click="resetForm">重置</span>
    </div>
  </div>
</template>

<style lang="scss" scoped src="@/styles/common.scss"></style>
<style scoped lang="scss">
.overview-park {
  > .item {
    border-bottom: 1px solid #EDF1F4;

    &:nth-last-child(-n+3) {
      border-bottom: none;
    }
  }
}

.headline {
  font-weight: bold;
  font-size: 14px;
  color: #242D33;
  line-height: 36px;
}

.subtitle, .label, .unit {
  font-weight: 400;
  font-size: 14px;
  color: #242D33;
  line-height: 36px;

  .tips {
    color: #6C7880;
  }
}

.label {
  font-size: 12px;
  margin-right: 12px;
  display: inline-block;
  width: 96px;
  text-align: right;
}

.unit {
  font-size: 12px;
  margin-left: 12px;
}

.row-year {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.row-title {
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 20px;

  .icon-delete {
    position: absolute;
    right: 0;
  }

  &.center {
    text-align: center;
    justify-content: center;
    padding-right: 0;
  }
}

.table-delete {
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-btn {
  display: inline-flex;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border: 1px solid #3984F5;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #3984F5;
  cursor: pointer;
  padding: 0 16px;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  &:hover {
    background-color: #3984F5;
    color: #fff;

    .icon-add {
      background-image: url("~@/assets/icon/add_white_icon@2x.png");
    }
  }
}

.table-row-add {

}

.table-add {
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.col-2 {
  display: grid;
  grid-template-columns: 500px minmax(500px, 1fr);
  grid-column-gap: 20px;
}

.btn_box {
  margin-top: 0;
}
</style>