<script>
import HorizontalTable
  from "@/views/system/organization/dataReporting/components/common/horizontal-table/HorizontalTable.vue";
import upload from "@/views/system/organization/dataReporting/components/common/upload/upload.vue";
import EditTag from "@/components/common/EditTag.vue";
import dataTable from "@/views/system/organization/dataReporting/components/common/table/dataTable.vue";

const DEFAULT_FORM = {
  qyryzb: [
    {
      personnel: '车间人员',
      number: null,
      proportion: null
    },
    {
      personnel: '研发人员',
      number: null,
      proportion: null
    },
    {
      personnel: '销售人员',
      number: null,
      proportion: null
    },
    {
      personnel: '采购人员',
      number: null,
      proportion: null
    },
    {
      personnel: '管理人员',
      number: null,
      proportion: null
    }
  ],
  qysccxzb: [
    {
      productionLine: '车间面积',
      number: null,
      utilizationRate: null
    },
    {
      productionLine: '产线数量',
      number: null,
      utilizationRate: null
    },
    {
      productionLine: '智能产线数',
      number: null,
      utilizationRate: null
    }
  ],
  qyyf: {
    tjsj: {
      yffy: null,
      yfcgsl: null,
      zlsl: null
    },
    xlfb: [
      {
        educational: '本科以下',
        proportion: null
      },
      {
        educational: '本科',
        proportion: null
      },
      {
        educational: '研究生及以上',
        proportion: null
      }
    ]
  },
  ccwl: {
    tjsj: {
      ccmj: null,
      cclyl: null,
      cczq: null
    },
    wlfszb: [
      {
        type: '公路运输',
        proportion: null
      },
      {
        type: '铁路运输',
        proportion: null
      },
      {
        type: '其他',
        proportion: null
      }
    ],
    wlcb: [
      {
        month: '1月',
        money: null
      },
      {
        month: '2月',
        money: null
      },
      {
        month: '3月',
        money: null
      },
      {
        month: '4月',
        money: null
      },
      {
        month: '5月',
        money: null
      },
      {
        month: '6月',
        money: null
      }
    ]
  },
  qyjbxx: {
    qyxct: {
      original_file_name: "",
      file_path: "",
      file_url: ""
    },
    jbxx: {
      rygm: null,
      gslx: null,
      sshy: null,
      tcsj: null,
      ssyq: null,
    }
  },
  qygm: {
    ncz: null,
    ys: null,
    cgl: null,
    yd: null,
  },
  qyzj: {
    szqk: [
      {
        time: '第一季度',
        income: null,
        revenueYearOnYear: null,
        expenditure: null,
        expenditureYearOnYear: null
      },
      {
        time: '第二季度',
        income: null,
        revenueYearOnYear: null,
        expenditure: null,
        expenditureYearOnYear: null
      },
      {
        time: '第三季度',
        income: null,
        revenueYearOnYear: null,
        expenditure: null,
        expenditureYearOnYear: null
      },
      {
        time: '第四季度',
        income: null,
        revenueYearOnYear: null,
        expenditure: null,
        expenditureYearOnYear: null
      }
    ],
    cbbdt: [
      {
        time: '2020年',
        asset: null,
        liabilities: null,
        cash: null,
        profit: null,
        income: null,
        expenditure: null
      },
      {
        time: '2021年',
        asset: null,
        liabilities: null,
        cash: null,
        profit: null,
        income: null,
        expenditure: null
      },
      {
        time: '2022年',
        asset: null,
        liabilities: null,
        cash: null,
        profit: null,
        income: null,
        expenditure: null
      },
      {
        time: '2023年',
        asset: null,
        liabilities: null,
        cash: null,
        profit: null,
        income: null,
        expenditure: null
      }
    ],
    qysxfstj: null,
    btbkfstj: [
      {
        plate: '供应数据',
        fraction: null
      },
      {
        plate: '物流数据',
        fraction: null
      },
      {
        plate: '生产数据',
        fraction: null
      },
      {
        plate: '服务数据',
        fraction: null
      },
      {
        plate: '交易数据',
        fraction: null
      },
      {
        plate: '求购数据',
        fraction: null
      }
    ]
  },
  qycp: {
    zyxscp: [],
    fkfszb: [
      {
        type: '月付',
        proportion: null
      },
      {
        type: '季付',
        proportion: null
      },
      {
        type: '半年付',
        proportion: null
      },
      {
        type: '其他',
        proportion: null
      }
    ],
  },
  qyxxh: [
    {
      plate: '生产管理',
      number: null
    },
    {
      plate: '企业办公',
      number: null
    },
    {
      plate: '营销管理',
      number: null
    },
    {
      plate: '产品设计',
      number: null
    }
  ],
  qysl: {
    zscq: [
      {
        type: '商标',
        propertyRightNumber: null,
        industryAverage: null,
        regionalAverage: null
      },
      {
        type: '证书',
        propertyRightNumber: null,
        industryAverage: null,
        regionalAverage: null
      }
    ],
    zlfl: [
      {
        type: '实用新型专利',
        proportion: null
      },
      {
        type: '发明专利',
        proportion: null
      },
      {
        type: '外观专利',
        proportion: null
      }
    ],
    zdzz: [
      {
        qualificationName: null,
        certificateNumber: null,
        issuingUnit: null,
        dateOfIssuance: null
      },
      {
        qualificationName: null,
        certificateNumber: null,
        issuingUnit: null,
        dateOfIssuance: null
      }
    ]
  }
}

export default {
  name: "corporateProfile", // 企业画像
  inject: ["updateEditStatus"], // 注入updateEditStatus编辑状态，用来判断是否提示保存 0：未编辑，1：编辑中，2：已保存方法
  components: {
    HorizontalTable,
    upload,
    EditTag,
    dataTable
  },
  data() {
    return {
      id: null,
      loading: false,
      // 是否重置表单
      isReset: false,
      isEdit: false,
      dict: {
        companyType: [{
          value: '有限责任公司',
          label: '有限责任公司'
        }, {
          value: '无限责任公司',
          label: '无限责任公司'
        }, {
          value: '股份有限公司',
          label: '股份有限公司'
        }, {
          value: '两合公司',
          label: '两合公司'
        }, {
          value: '股份两合公司',
          label: '股份两合公司'
        }],
      },
      form: JSON.parse(JSON.stringify(DEFAULT_FORM)),
      tableColumns: {
        qysccxzb: [
          {
            label: '数量/产线',
            prop: 'productionLine'
          },
          {
            label: '数量',
            prop: 'number'
          },
          {
            label: '利用率（%）',
            prop: 'utilizationRate'
          }
        ],
        qyryzb: [
          {
            label: '数量/人员',
            prop: 'personnel'
          },
          {
            label: '人员数量（人）',
            prop: 'number'
          },
          {
            label: '占比（%）',
            prop: 'proportion'
          }
        ],
        xlfb: [
          {
            label: '占比/团队学历',
            prop: 'educational'
          },
          {
            label: '占比（%）',
            prop: 'proportion'
          }
        ],
        wlfszb: [
          {
            label: '占比/类型',
            prop: 'type'
          },
          {
            label: '占比（%）',
            prop: 'proportion'
          }
        ],
        wlcb: [
          {
            label: '金额/月份',
            prop: 'month'
          },
          {
            label: '金额（万元）',
            prop: 'money'
          }
        ],
        szqk: [
          {
            label: '金额/时间',
            prop: 'time'
          },
          {
            label: '收入（千万）',
            prop: 'income'
          },
          {
            label: '收入同比（%）',
            prop: 'revenueYearOnYear'
          },
          {
            label: '支出（千万）',
            prop: 'expenditure'
          },
          {
            label: '支出同比（%）',
            prop: 'expenditureYearOnYear'
          }
        ],
        cbbdt: [
          {
            label: '类型/时间',
            prop: 'time'
          },
          {
            label: '资产（千万）',
            prop: 'asset'
          },
          {
            label: '负债（千万）',
            prop: 'liabilities'
          },
          {
            label: '现金（千万）',
            prop: 'cash'
          },
          {
            label: '利润（千万）',
            prop: 'profit'
          },
          {
            label: '收入（千万）',
            prop: 'income'
          },
          {
            label: '支出（千万）',
            prop: 'expenditure'
          }
        ],
        btbkfstj: [
          {
            label: '分数/板块',
            prop: 'plate'
          },
          {
            label: '分数',
            prop: 'fraction'
          }
        ],
        fkfszb: [
          {
            label: '占比/类型',
            prop: 'type'
          },
          {
            label: '占比（%）',
            prop: 'proportion'
          }
        ],
        qyxxh: [
          {
            label: '数量/板块',
            prop: 'plate'
          },
          {
            label: '软件数量（个）',
            prop: 'number'
          }
        ],
        zscq: [
          {
            label: '数量/类型',
            prop: 'type'
          },
          {
            label: '企业知识产权数',
            prop: 'propertyRightNumber'
          },
          {
            label: '行业平均数',
            prop: 'industryAverage'
          },
          {
            label: '区域平均数',
            prop: 'regionalAverage'
          }
        ],
        zlfl: [
          {
            label: '占比/类型',
            prop: 'type'
          },
          {
            label: '占比（%）',
            prop: 'proportion'
          }
        ],
        zdzz: [
          {
            label: '资质名称',
            prop: 'qualificationName'
          },
          {
            label: '证书编号',
            prop: 'certificateNumber'
          },
          {
            label: '颁发单位',
            prop: 'issuingUnit'
          },
          {
            label: '颁发时间',
            prop: 'dateOfIssuance'
          }
        ]
      }
    }
  },
  watch: {
    form: {
      handler(val, oldVal) {
        if (!this.isReset && !this.isEdit) {
          this.updateEditStatus(1)
        } else if (this.isReset) {
          this.isReset = false;
          this.updateEditStatus(0)
        } else if (this.isEdit) {
          this.isEdit = false;
          this.updateEditStatus(2)
        }
      },
      deep: true
    }
  },
  mounted() {
    this.getDetail('2-2')
  },
  methods: {
    getDetail(step) {
      const data = {
        organize_id: this.$route.query.id,
        first_level: step.split('-')[0],
        second_level: step.split('-')[1]
      }
      this.loading = true;
      this.$api.dataReportingDetail(data).then(res => {
        this.loading = false;
        this.id = res.data.id || null;
        this.isReset = true;
        this.loading = false;
        if (res.data.content) {
          this.form = JSON.parse(res.data.content)
        }
      })
    },
    save() {
      const params = {
        id: this.id,
        organize_id: this.$route.query.id,
        first_level: 2,
        second_level: 2,
        content: JSON.stringify(this.form)
      }
      this.$api.saveDataPark(params).then(res => {
        this.id = res.data.id;
        this.form = JSON.parse(JSON.parse(res.data.content));
        this.isEdit = true;
        this.$message.success('保存成功')
        this.updateEditStatus(2)
      })
    },
    handleNumericInput(obj, prop, event) {
      if (obj && prop in obj) {
        obj[prop] = event.replace(/[^\d]/g, '');
      }
    },
    // 重置表单
    resetForm() {
      this.$confirm('确定清空当前页面的数据内容吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.form = JSON.parse(JSON.stringify(DEFAULT_FORM))
        this.isReset = true
      })
    }
  }
}
</script>

<template>
  <div class="overview-park pt-10 pb-20" v-loading="loading">
    <div class="item pt-20 pb-20">
      <h3 class="headline">一、企业人员</h3>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">不同类型的人员占比<span class="tips">（建议不超过7个类型）</span></div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.qyryzb" :columns="tableColumns.qyryzb">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'personnel'">
                  <div class="row-title pointer clearfix" v-if="scope.row.personnel !== '' && !scope.row.$isFocused"
                       @dblclick="$set(scope.row, '$isFocused', true)">
                    {{ scope.row.personnel }} <i class="fr icon-delete icon17 pointer"
                                                 @click="form.qyryzb.splice(scope.$index, 1)"></i></div>
                  <el-input v-else v-model="scope.row.personnel" placeholder="请输入行业名称"
                            @focus="$set(scope.row, '$isFocused', true)"
                            @blur="$set(scope.row, '$isFocused', false)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'number'">
                  <el-input v-model="scope.row.number" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'number', event)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'proportion'">
                  <el-input v-model="scope.row.proportion" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'proportion', event)"></el-input>
                </template>
              </template>
              <div class="table-add" slot="suffix">
                <i class="icon-add icon17 pointer"
                   @click="form.qyryzb.push({personnel: '', number: null, proportion: null})"></i>
              </div>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">二、企业生产</h3>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">不同类型的产线占比</div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.qysccxzb" :columns="tableColumns.qysccxzb" cell-width="120px">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'productionLine'">
                  <div class="row-title">
                    {{ scope.row.productionLine }}
                  </div>
                </template>
                <template v-else-if="scope.prop === 'number'">
                  <el-input v-model="scope.row.number" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'number', event)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'utilizationRate'">
                  <el-input v-model="scope.row.utilizationRate" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'utilizationRate', event)"></el-input>
                </template>
              </template>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">三、企业研发</h3>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">1、统计数据</div>
        <div class="content">
          <div class="item-row mb-16">
            <span class="label">研发费用</span>
            <el-input v-model="form.qyyf.tjsj.yffy" placeholder="请输入"
                      @input="event => handleNumericInput(form.qyyf.tjsj, 'yffy', event)"
                      style="width: 340px;"></el-input>
            <span class="unit">万元</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">研发成果数量</span>
            <el-input v-model="form.qyyf.tjsj.yfcgsl" placeholder="请输入"
                      @input="event => handleNumericInput(form.qyyf.tjsj, 'yfcgsl', event)"
                      style="width: 340px;"></el-input>
            <span class="unit">个</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">专利数量</span>
            <el-input v-model="form.qyyf.tjsj.zlsl" placeholder="请输入"
                      @input="event => handleNumericInput(form.qyyf.tjsj, 'zlsl', event)"
                      style="width: 340px;"></el-input>
            <span class="unit">个</span>
          </div>
        </div>
      </div>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">2、研发团队分布<span class="tips">（建议不超过7个类型）</span></div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.qyyf.xlfb" :columns="tableColumns.xlfb">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'educational'">
                  <div class="row-title pointer clearfix" v-if="scope.row.educational !== '' && !scope.row.$isFocused"
                       @dblclick="$set(scope.row, '$isFocused', true)">
                    {{ scope.row.educational }} <i class="fr icon-delete icon17 pointer"
                                                   @click="form.qyyf.xlfb.splice(scope.$index, 1)"></i></div>
                  <el-input v-else v-model="scope.row.educational" placeholder="请输入行业名称"
                            @focus="$set(scope.row, '$isFocused', true)"
                            @blur="$set(scope.row, '$isFocused', false)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'proportion'">
                  <el-input v-model="scope.row.proportion" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'proportion', event)"></el-input>
                </template>
              </template>
              <div class="table-add" slot="suffix">
                <i class="icon-add icon17 pointer"
                   @click="form.qyyf.xlfb.push({educational: '', proportion: null})"></i>
              </div>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">四、仓储物流</h3>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">1、统计数据</div>
        <div class="content">
          <div class="item-row mb-16">
            <span class="label">仓储面积</span>
            <el-input v-model="form.ccwl.tjsj.ccmj" placeholder="请输入"
                      @input="event => handleNumericInput(form.ccwl.tjsj, 'ccmj', event)"
                      style="width: 340px;"></el-input>
            <span class="unit">平方</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">仓储利用率</span>
            <el-input v-model="form.ccwl.tjsj.cclyl" placeholder="请输入"
                      @input="event => handleNumericInput(form.ccwl.tjsj, 'cclyl', event)"
                      style="width: 340px;"></el-input>
            <span class="unit">%</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">仓储周期</span>
            <el-input v-model="form.ccwl.tjsj.cczq" placeholder="请输入"
                      @input="event => handleNumericInput(form.ccwl.tjsj, 'cczq', event)"
                      style="width: 340px;"></el-input>
            <span class="unit">天</span>
          </div>
        </div>
      </div>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">2、物流方式占比<span class="tips">（建议不超过5个类型）</span></div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.ccwl.wlfszb" :columns="tableColumns.wlfszb">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'type'">
                  <div class="row-title pointer clearfix" v-if="scope.row.type !== '' && !scope.row.$isFocused"
                       @dblclick="$set(scope.row, '$isFocused', true)">
                    {{ scope.row.type }} <i class="fr icon-delete icon17 pointer"
                                            @click="form.ccwl.wlfszb.splice(scope.$index, 1)"></i></div>
                  <el-input v-else v-model="scope.row.type" placeholder="请输入物流方式"
                            @focus="$set(scope.row, '$isFocused', true)"
                            @blur="$set(scope.row, '$isFocused', false)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'proportion'">
                  <el-input v-model="scope.row.proportion" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'proportion', event)"></el-input>
                </template>
              </template>
              <div class="table-add" slot="suffix">
                <i class="icon-add icon17 pointer"
                   @click="form.ccwl.wlfszb.push({type: '', proportion: null})"></i>
              </div>
            </horizontal-table>
          </div>
        </div>
      </div>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">3、物流成本<span class="tips">（建议不超过6个月份）</span></div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.ccwl.wlcb" :columns="tableColumns.wlcb">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'month'">
                  <div class="row-title pointer clearfix" v-if="scope.row.month !== '' && !scope.row.$isFocused"
                       @dblclick="$set(scope.row, '$isFocused', true)">
                    {{ scope.row.month }} <i class="fr icon-delete icon17 pointer"
                                             @click="form.ccwl.wlcb.splice(scope.$index, 1)"></i></div>
                  <el-input v-else v-model="scope.row.month" placeholder="请输入月份"
                            @focus="$set(scope.row, '$isFocused', true)"
                            @blur="$set(scope.row, '$isFocused', false)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'money'">
                  <el-input v-model="scope.row.money" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'money', event)"></el-input>
                </template>
              </template>
              <div class="table-add" slot="suffix">
                <i class="icon-add icon17 pointer"
                   @click="form.ccwl.wlcb.push({month: '', money: null})"></i>
              </div>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">五、企业基本信息</h3>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">1、企业宣传图</div>
        <div class="content">
          <div class="item-row mb-16">
            <upload v-model="form.qyjbxx.qyxct" accept=".avi,.wmv,.mov,.jpg,.png"
                    placeholder="请添加企业宣传图或宣传视频，支持扩展名：.avi  .wmv  .mov  .jpg  .png，大小限制20M以内"></upload>
          </div>
        </div>
      </div>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">2、基本信息</div>
        <div class="content col-2">
          <div class="item-row mb-16">
            <span class="label">人员规模</span>
            <el-input v-model="form.qyjbxx.jbxx.rygm" placeholder="请输入"
                      @input="event => handleNumericInput(form.qyjbxx.jbxx, 'rygm', event)"
                      style="width: 340px;"></el-input>
            <span class="unit">人</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">公司类型</span>
            <el-select v-model="form.qyjbxx.jbxx.gslx" placeholder="请选择" style="width: 340px;">
              <el-option
                  v-for="item in dict.companyType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            <span class="unit"></span>
          </div>
          <div class="item-row mb-16">
            <span class="label">所属行业</span>
            <el-input v-model="form.qyjbxx.jbxx.sshy" placeholder="请输入行业" maxlength="10" show-word-limit
                      style="width: 340px;"></el-input>
            <span class="unit"></span>
          </div>
          <div class="item-row mb-16">
            <span class="label">投产时间</span>
            <el-input v-model="form.qyjbxx.jbxx.tcsj" placeholder="请输入年份"
                      style="width: 340px;"></el-input>
            <span class="unit"></span>
          </div>
          <div class="item-row mb-16">
            <span class="label">所属园区</span>
            <el-input v-model="form.qyjbxx.jbxx.ssyq" placeholder="请输入园区"
                      style="width: 340px;"></el-input>
            <span class="unit"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">六、企业规模</h3>
      <div class="item-content pl-25">
        <div class="content col-2 pt-20">
          <div class="item-row mb-16">
            <span class="label">年产值</span>
            <el-input v-model="form.qygm.ncz" placeholder="请输入"
                      @input="event => handleNumericInput(form.qygm, 'ncz', event)" style="width: 340px;"></el-input>
            <span class="unit">万元</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">用水</span>
            <el-input v-model="form.qygm.ys" placeholder="请输入"
                      @input="event => handleNumericInput(form.qygm, 'ys', event)" style="width: 340px;"></el-input>
            <span class="unit">万吨</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">采购量</span>
            <el-input v-model="form.qygm.cgl" placeholder="请输入"
                      @input="event => handleNumericInput(form.qygm, 'cgl', event)" style="width: 340px;"></el-input>
            <span class="unit">万元</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">用电</span>
            <el-input v-model="form.qygm.yd" placeholder="请输入"
                      @input="event => handleNumericInput(form.qygm, 'yd', event)" style="width: 340px;"></el-input>
            <span class="unit">万度</span>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">七、企业资金</h3>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">1、收支情况</div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.qyzj.szqk" :columns="tableColumns.szqk" cell-width="120px">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'time'">
                  <div class="row-title">
                    {{ scope.row.time }}
                  </div>
                </template>
                <template v-else-if="scope.prop === 'income'">
                  <el-input v-model="scope.row.income" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'income', event)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'revenueYearOnYear'">
                  <el-input v-model="scope.row.revenueYearOnYear" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'revenueYearOnYear', event)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'expenditure'">
                  <el-input v-model="scope.row.expenditure" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'expenditure', event)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'expenditureYearOnYear'">
                  <el-input v-model="scope.row.expenditureYearOnYear" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'expenditureYearOnYear', event)"></el-input>
                </template>
              </template>
            </horizontal-table>
          </div>
        </div>
      </div>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">2、财报波动图</div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.qyzj.cbbdt" :columns="tableColumns.cbbdt">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'time'">
                  <div class="row-title pointer clearfix" v-if="scope.row.time !== '' && !scope.row.$isFocused"
                       @dblclick="$set(scope.row, '$isFocused', true)">
                    {{ scope.row.time }} <i class="fr icon-delete icon17 pointer"
                                                 @click="form.qyzj.cbbdt.splice(scope.$index, 1)"></i></div>
                  <el-input v-else v-model="scope.row.time" placeholder="请输入年份"
                            @focus="$set(scope.row, '$isFocused', true)"
                            @blur="$set(scope.row, '$isFocused', false)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'asset'">
                  <el-input v-model="scope.row.asset" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'asset', event)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'liabilities'">
                  <el-input v-model="scope.row.liabilities" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'liabilities', event)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'cash'">
                  <el-input v-model="scope.row.cash" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'cash', event)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'profit'">
                  <el-input v-model="scope.row.profit" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'profit', event)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'income'">
                  <el-input v-model="scope.row.income" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'income', event)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'expenditure'">
                  <el-input v-model="scope.row.expenditure" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'expenditure', event)"></el-input>
                </template>
              </template>
              <div class="table-add" slot="suffix">
                <i class="icon-add icon17 pointer"
                   @click="form.qyzj.cbbdt.push({time: '', asset: null, liabilities: null, cash: null, profit: null, income: null, expenditure: null})"></i>
              </div>
            </horizontal-table>
          </div>
        </div>
      </div>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">3、企业授信</div>
        <div class="content">
          <div class="item-row mb-16">
            <span class="label">分数统计</span>
            <el-input v-model="form.qyzj.qysxfstj" placeholder="请输入"
                      @input="event => handleNumericInput(form.qyzj, 'qysxfstj', event)"
                      style="width: 340px;"></el-input>
            <span class="unit">分</span>
          </div>
        </div>
        <div class="subtitle mb-10">不同板块的分数统计<span class="tips">（建议不超过7个板块）</span></div>
        <div class="content">
          <horizontal-table :data="form.qyzj.btbkfstj" :columns="tableColumns.btbkfstj">
            <template #table-cell="scope">
              <template v-if="scope.prop === 'plate'">
                <div class="row-title pointer clearfix" v-if="scope.row.plate !== '' && !scope.row.$isFocused"
                     @dblclick="$set(scope.row, '$isFocused', true)">
                  {{ scope.row.plate }} <i class="fr icon-delete icon17 pointer"
                                          @click="form.qyzj.btbkfstj.splice(scope.$index, 1)"></i></div>
                <el-input v-else v-model="scope.row.plate" placeholder="请输入板块名称"
                          @focus="$set(scope.row, '$isFocused', true)"
                          @blur="$set(scope.row, '$isFocused', false)"></el-input>
              </template>
              <template v-else-if="scope.prop === 'fraction'">
                <el-input v-model="scope.row.fraction" placeholder="请输入"
                          @input="event => handleNumericInput(scope.row, 'fraction', event)"></el-input>
              </template>
            </template>
            <div class="table-add" slot="suffix">
              <i class="icon-add icon17 pointer"
                 @click="form.qyzj.btbkfstj.push({plate: '', fraction: null})"></i>
            </div>
          </horizontal-table>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">八、企业产品</h3>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">1、主要销售产品<span class="tips">（建议添加8-15个产品）</span></div>
        <div class="content">
          <div class="item-row mb-16">
            <edit-tag placeholder="请输入产品名称（不超过6个字）" :input-maxlength="6" v-model="form.qycp.zyxscp"
                      input-width="300px"></edit-tag>
          </div>
        </div>
      </div>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">2、付款方式占比</div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.qycp.fkfszb" :columns="tableColumns.fkfszb" cell-width="120px">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'type'">
                  <div class="row-title">
                    {{ scope.row.type }}
                  </div>
                </template>
                <template v-else-if="scope.prop === 'proportion'">
                  <el-input v-model="scope.row.proportion" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'proportion', event)"></el-input>
                </template>
              </template>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">九、企业信息化</h3>
      <div class="item-content pl-25">
        <div class="content pt-20">
          <div class="item-row mb-16">
            <horizontal-table :data="form.qyxxh" :columns="tableColumns.qyxxh" cell-width="120px">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'plate'">
                  <div class="row-title">
                    {{ scope.row.plate }}
                  </div>
                </template>
                <template v-else-if="scope.prop === 'number'">
                  <el-input v-model="scope.row.number" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'number', event)"></el-input>
                </template>
              </template>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">十、企业实力</h3>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">1、知识产权</div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.qysl.zscq" :columns="tableColumns.zscq" cell-width="120px">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'type'">
                  <div class="row-title">
                    {{ scope.row.type }}
                  </div>
                </template>
                <template v-else-if="scope.prop === 'propertyRightNumber'">
                  <el-input v-model="scope.row.propertyRightNumber" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'propertyRightNumber', event)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'industryAverage'">
                  <el-input v-model="scope.row.industryAverage" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'industryAverage', event)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'regionalAverage'">
                  <el-input v-model="scope.row.regionalAverage" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'regionalAverage', event)"></el-input>
                </template>
              </template>
            </horizontal-table>
          </div>
        </div>
      </div>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">2、专利分类<span class="tips">（建议不超过5个类型）</span></div>
        <div class="content mb-10">
          <horizontal-table :data="form.qysl.zlfl" :columns="tableColumns.zlfl">
            <template #table-cell="scope">
              <template v-if="scope.prop === 'type'">
                <div class="row-title pointer clearfix" v-if="scope.row.type !== '' && !scope.row.$isFocused"
                     @dblclick="$set(scope.row, '$isFocused', true)">
                  {{ scope.row.type }} <i class="fr icon-delete icon17 pointer"
                                           @click="form.qysl.zlfl.splice(scope.$index, 1)"></i></div>
                <el-input v-else v-model="scope.row.type" placeholder="请输入专利类型"
                          @focus="$set(scope.row, '$isFocused', true)"
                          @blur="$set(scope.row, '$isFocused', false)"></el-input>
              </template>
              <template v-else-if="scope.prop === 'proportion'">
                <el-input v-model="scope.row.proportion" placeholder="请输入"
                          @input="event => handleNumericInput(scope.row, 'proportion', event)"></el-input>
              </template>
            </template>
            <div class="table-add" slot="suffix">
              <i class="icon-add icon17 pointer"
                 @click="form.qysl.zlfl.push({type: '', proportion: null})"></i>
            </div>
          </horizontal-table>
        </div>
      </div>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">3、重点资质</div>
        <div class="content">
          <data-table :data="form.qysl.zdzz" :columns="tableColumns.zdzz">
            <template #table-cell="scope">
              <template v-if="scope.prop === 'dateOfIssuance'">
                <el-input v-model="scope.row.dateOfIssuance" placeholder="输入年月，如2024.12"></el-input>
              </template>
              <template v-else>
                <el-input v-model="scope.row[scope.prop]" placeholder="请输入"></el-input>
              </template>
            </template>
            <template #row-suffix="scope">
              <div class="table-delete">
                <i class="fr icon-delete icon17 pointer"
                   @click="form.qysl.zdzz.splice(scope.$index, 1)"></i>
              </div>
            </template>
            <div class="table-row-add pt-20" slot="table-suffix">
              <span class="add-btn" @click="form.qysl.zdzz.push({qualificationName: null, certificateNumber: null, issuingUnit: null, dateOfIssuance: null})"><i class="icon-add icon17 pointer mr-5"></i>新增一行</span>
            </div>
          </data-table>
        </div>
      </div>
    </div>
    <div class="item pb-20 pl-25 btn_box">
      <span class="btn blue" @click="save">确定</span>
      <span class="btn" @click="resetForm">重置</span>
    </div>
  </div>
</template>

<style lang="scss" scoped src="@/styles/common.scss"></style>
<style scoped lang="scss">
.overview-park {
  > .item {
    border-bottom: 1px solid #EDF1F4;

    &:nth-last-child(-n+3) {
      border-bottom: none;
    }
  }
}

.headline {
  font-weight: bold;
  font-size: 14px;
  color: #242D33;
  line-height: 36px;
}

.subtitle, .label, .unit {
  font-weight: 400;
  font-size: 14px;
  color: #242D33;
  line-height: 36px;

  .tips {
    color: #6C7880;
  }
}

.label {
  font-size: 12px;
  margin-right: 12px;
  display: inline-block;
  width: 72px;
  text-align: right;
}

.unit {
  font-size: 12px;
  margin-left: 12px;
}

.row-title {
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 20px;

  .icon-delete {
    position: absolute;
    right: 0;
  }

  &.center {
    text-align: center;
    justify-content: center;
    padding-right: 0;
  }
}

.table-add {
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-delete {
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-btn {
  display: inline-flex;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border: 1px solid #3984F5;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #3984F5;
  cursor: pointer;
  padding: 0 16px;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  &:hover {
    background-color: #3984F5;
    color: #fff;

    .icon-add {
      background-image: url("~@/assets/icon/add_white_icon@2x.png");
    }
  }
}

.col-2 {
  display: grid;
  grid-template-columns: 500px minmax(500px, 1fr);
  grid-column-gap: 20px;
}

.btn_box {
  margin-top: 0;
}
</style>