<script>
import HorizontalTable from "@/views/system/organization/dataReporting/components/common/horizontal-table/HorizontalTable.vue";
import HorizontalTableYear from "@/views/system/organization/dataReporting/components/common/horizontal-table/HorizontalTableYear.vue";

const thisYear = new Date().getFullYear();

function getYearData(year, template, num = 3) {
  if (typeof year === "string" && year !== "") {
    year = parseInt(year)
  }
  let data = [];
  for (let i = 0; i < num; i++) {
    let item = {
      year: year ? year - i : "",
      data: template
    }

    data.push(item)
  }
  return data
}
const DEFAULT_TEMPLATE = [
  {
    name:'规上工业产值',
    money: null
  },
  {
    name:'规上工业税金',
    money: null
  },
  {
    name:'规上工业利润',
    money: null
  },
  {
    name:'规上工业增加值',
    money: null
  },
  {
    name:'工业固定资产投产',
    money: null
  },
  {
    name:'基础设施建设投资',
    money: null
  }
]
const DEFAULT_FORM = {
  jjyxzb:getYearData(thisYear, JSON.parse(JSON.stringify(DEFAULT_TEMPLATE))),
  yqydxx: [
    {
      acreageType: '园区规划建设面积',
      area:null
    },
    {
      acreageType: '园区建成区面积',
      area:null
    },
    {
      acreageType: '园区已征地面积',
      area:null
    },
    {
      acreageType: '园区已供应工业用地',
      area:null
    },
  ],
  yqjbxx: {
    gsqysl: null,
    zjqysl: null,
    rzqysl: null,
    cyry: null,
    clsj: null,
    zdcy: null,
    tcqysl: null
  },
  qysscyfb: [
    {
      industry: '精致茶制业',
      enterprisesNum: null,
      totalAnnualValue: null,
      totalLandArea: null,
      averageValuePerAcre:null
    },
    {
      industry: '精致咖啡制业',
      enterprisesNum: null,
      totalAnnualValue: null,
      totalLandArea: null,
      averageValuePerAcre:null
    },
    {
      industry: '绿色食品',
      enterprisesNum: null,
      totalAnnualValue: null,
      totalLandArea: null,
      averageValuePerAcre:null
    },
    {
      industry: '酒/饮料',
      enterprisesNum: null,
      totalAnnualValue: null,
      totalLandArea: null,
      averageValuePerAcre:null
    },
    {
      industry: '其他',
      enterprisesNum: null,
      totalAnnualValue: null,
      totalLandArea: null,
      averageValuePerAcre:null
    },
  ],
  nh: {
    sxhhbfx: [
      {
        month: '1月',
        waterConsumption: null,
        chainRatio: null
      },
      {
        month: '2月',
        waterConsumption: null,
        chainRatio: null
      },
      {
        month: '3月',
        waterConsumption: null,
        chainRatio: null
      },
      {
        month: '4月',
        waterConsumption: null,
        chainRatio: null
      },
      {
        month: '5月',
        waterConsumption: null,
        chainRatio: null
      },
      {
        month: '6月',
        waterConsumption: null,
        chainRatio: null
      },
      {
        month: '7月',
        waterConsumption: null,
        chainRatio: null
      },
      {
        month: '8月',
        waterConsumption: null,
        chainRatio: null
      },
      {
        month: '9月',
        waterConsumption: null,
        chainRatio: null
      },
      {
        month: '10月',
        waterConsumption: null,
        chainRatio: null
      },
      {
        month: '11月',
        waterConsumption: null,
        chainRatio: null
      },
      {
        month: '12月',
        waterConsumption: null,
        chainRatio: null
      }
    ],
    dxhhbfx: [
      {
        month: '1月',
        electricityConsumption: null,
        chainRatio: null
      },
      {
        month: '2月',
        electricityConsumption: null,
        chainRatio: null
      },
      {
        month: '3月',
        electricityConsumption: null,
        chainRatio: null
      },
      {
        month: '4月',
        electricityConsumption: null,
        chainRatio: null
      },
      {
        month: '5月',
        electricityConsumption: null,
        chainRatio: null
      },
      {
        month: '6月',
        electricityConsumption: null,
        chainRatio: null
      },
      {
        month: '7月',
        electricityConsumption: null,
        chainRatio: null
      },
      {
        month: '8月',
        electricityConsumption: null,
        chainRatio: null
      },
      {
        month: '9月',
        electricityConsumption: null,
        chainRatio: null
      },
      {
        month: '10月',
        electricityConsumption: null,
        chainRatio: null
      },
      {
        month: '11月',
        electricityConsumption: null,
        chainRatio: null
      },
      {
        month: '12月',
        electricityConsumption: null,
        chainRatio: null
      }
    ],
    mxhhbfx: [
      {
        month: '1月',
        mediumUsed: null,
        chainRatio: null
      },
      {
        month: '2月',
        mediumUsed: null,
        chainRatio: null
      },
      {
        month: '3月',
        mediumUsed: null,
        chainRatio: null
      },
      {
        month: '4月',
        mediumUsed: null,
        chainRatio: null
      },
      {
        month: '5月',
        mediumUsed: null,
        chainRatio: null
      },
      {
        month: '6月',
        mediumUsed: null,
        chainRatio: null
      },
      {
        month: '7月',
        mediumUsed: null,
        chainRatio: null
      },
      {
        month: '8月',
        mediumUsed: null,
        chainRatio: null
      },
      {
        month: '9月',
        mediumUsed: null,
        chainRatio: null
      },
      {
        month: '10月',
        mediumUsed: null,
        chainRatio: null
      },
      {
        month: '11月',
        mediumUsed: null,
        chainRatio: null
      },
      {
        month: '12月',
        mediumUsed: null,
        chainRatio: null
      }
    ]
  },
}

export default {
  name: "parkPortrait", // 园区画像
  inject: ["updateEditStatus"], // 注入updateEditStatus编辑状态，用来判断是否提示保存 0：未编辑，1：编辑中，2：已保存方法
  components: {
    HorizontalTable,
    HorizontalTableYear
  },
  data() {
    return {
      id: null,
      loading:false,
      // 是否重置表单
      isReset: false,
      isEdit: false,
      form: JSON.parse(JSON.stringify(DEFAULT_FORM)),
      tableColumns: {
        jjyxzb:[
          {
            label: "金额/经济类型",
            prop: "name",
          },
          {
            label: "金额（千万）",
            prop: "money",
          }
        ],
        yqydxx: [
          {
            label: '面积/用地类型',
            prop: 'acreageType'
          },
          {
            label: '面积（平方公里）',
            prop: 'number'
          },
        ],
        qysscyfb: [
          {
            label: '数量/行业',
            prop: 'industry'
          },
          {
            label: '企业数（家）',
            prop: 'enterprisesNum'
          },
          {
            label: '合计年产值（亿元）',
            prop: 'totalAnnualValue'
          },
          {
            label: '合计占地（公顷）',
            prop: 'totalLandArea'
          },
          {
            label: '平均每亩产值（万）',
            prop: 'averageValuePerAcre'
          }
        ],
        sxhhbfx: [
          {
            label: '占比/行业',
            prop: 'month'
          },
          {
            label: '用水量（万吨）',
            prop: 'waterConsumption'
          },
          {
            label: '环比（%）',
            prop: 'chainRatio'
          }
        ],
        dxhhbfx: [
          {
            label: '占比/行业',
            prop: 'month'
          },
          {
            label: '用电量（万度）',
            prop: 'electricityConsumption'
          },
          {
            label: '环比（%）',
            prop: 'chainRatio'
          }
        ],
        mxhhbfx: [
          {
            label: '占比/行业',
            prop: 'month'
          },
          {
            label: '用媒量（千吨）',
            prop: 'mediumUsed'
          },
          {
            label: '环比（%）',
            prop: 'chainRatio'
          }
        ]
      }
    }
  },
  mounted() {
    this.getDetail('2-3')
  },
  watch: {
    form: {
      handler(val, oldVal) {
        if (!this.isReset && !this.isEdit) {
          this.updateEditStatus(1)
        } else if (this.isReset) {
          this.isReset = false;
          this.updateEditStatus(0)
        } else if (this.isEdit) {
          this.isEdit = false;
          this.updateEditStatus(2)
        }
      },
      deep: true
    }
  },
  methods: {
    getDetail(step) {
      const data = {
        organize_id: this.$route.query.id,
        first_level: step.split('-')[0],
        second_level: step.split('-')[1]
      }
      this.loading = true;
      this.$api.dataReportingDetail(data).then(res => {
        this.loading = false;
        this.id = res.data.id || null;
        this.isReset = true;
        this.loading = false;
        if (res.data.content) {
          this.form = JSON.parse(res.data.content)
        }
      })
    },
    save() {
      const params = {
        id: this.id,
        organize_id: this.$route.query.id,
        first_level: 2,
        second_level: 3,
        content: JSON.stringify(this.form)
      }
      console.log(this.form, 'form=========')
      this.$api.saveDataPark(params).then(res => {
        this.id = res.data.id;
        this.isEdit = true;
        this.form = JSON.parse(JSON.parse(res.data.content));
        this.$message.success('保存成功')
        this.updateEditStatus(2)
      })
    },
    handleNumericInput(obj, prop, event) {
      if (obj && prop in obj) {
        obj[prop] = event.replace(/[^\d]/g, '');
      }
    },
    // 重置表单
    resetForm() {
      this.$confirm('确定清空当前页面的数据内容吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.form = JSON.parse(JSON.stringify(DEFAULT_FORM))
        this.isReset = true
      })
    },
    addRow(type) {
      if (type === 'jjyxzb') {
        this.form.jjyxzb.push(getYearData('', JSON.parse(JSON.stringify(DEFAULT_TEMPLATE)), 1)[0])
      }
    }
  }
}
</script>

<template>
  <div class="overview-park pt-10 pb-20" v-loading="loading">
    <div class="item pt-20 pb-20">
      <h3 class="headline">一、经济运行指标</h3>
      <div class="item-content pl-25">
        <div class="subtitle mb-10"></div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table-year :data="form.jjyxzb" :columns="tableColumns.jjyxzb" cell-width="180px">
              <template #table-year-cell="scope">
                <div class="row-year pointer" v-if="scope.row.year !== '' && !scope.row.$isFocused" @dblclick="$set(scope.row, '$isFocused', true)">
                  {{ scope.row.year }}年
                </div>
                <div class="row-year" v-else>
                  <el-input v-model="scope.row.year" placeholder="请输入年份"
                            @focus="$set(scope.row, '$isFocused', true)" @blur="$set(scope.row, '$isFocused', false)"
                            @input="event => handleNumericInput(scope.row, 'year', event)"
                  ></el-input>
                </div>
              </template>
              <template #table-cell="scope">
                <template v-if="scope.prop === 'name'">
                  <div class="row-title">
                    {{ scope.row.name }}
                  </div>
                </template>
                <template v-else-if="scope.prop === 'money'">
                  <el-input v-model="scope.row.money" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'money', event)"></el-input>
                </template>
              </template>
              <template #row-suffix="scope">
                <div class="table-delete">
                  <i class="fr icon-delete icon17 pointer"
                     @click="form.jjyxzb.splice(scope.$index, 1)"></i>
                </div>
              </template>
              <div class="table-row-add pt-20" slot="table-suffix">
                <span class="add-btn" @click="addRow('jjyxzb')"><i class="icon-add icon17 pointer mr-5"></i>新增一行</span>
              </div>
            </horizontal-table-year>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">二、园区用地信息</h3>
      <div class="item-content pl-25">
        <div class="subtitle mb-10"></div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.yqydxx" :columns="tableColumns.yqydxx" cell-width="120px">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'acreageType'">
                  <div class="row-title">
                    {{ scope.row.acreageType }}
                  </div>
                </template>
                <template v-else-if="scope.prop === 'number'">
                  <el-input v-model="scope.row.number" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'number', event)"></el-input>
                </template>
              </template>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">三、园区基本信息</h3>
      <div class="item-content pl-25">
        <div class="content col-2 pt-20">
          <div class="item-row mb-16">
            <span class="label">规上企业数量</span>
            <el-input v-model="form.yqjbxx.gsqysl" placeholder="请输入"
                      @input="event => handleNumericInput(form.yqjbxx, 'gsqysl', event)" style="width: 340px;"></el-input>
            <span class="unit">个</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">在建企业数量</span>
            <el-input v-model="form.yqjbxx.zjqysl" placeholder="请输入"
                      @input="event => handleNumericInput(form.yqjbxx, 'zjqysl', event)" style="width: 340px;"></el-input>
            <span class="unit">个</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">入驻企业数量</span>
            <el-input v-model="form.yqjbxx.rzqysl" placeholder="请输入"
                      @input="event => handleNumericInput(form.yqjbxx, 'rzqysl', event)" style="width: 340px;"></el-input>
            <span class="unit">个</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">从业人员</span>
            <el-input v-model="form.yqjbxx.cyry" placeholder="请输入"
                      @input="event => handleNumericInput(form.yqjbxx, 'cyry', event)" style="width: 340px;"></el-input>
            <span class="unit">人</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">成立时间</span>
            <el-input v-model="form.yqjbxx.clsj" placeholder="请输入年份"
                      @input="event => handleNumericInput(form.yqjbxx, 'clsj', event)" style="width: 340px;"></el-input>
            <span class="unit"></span>
          </div>
          <div class="item-row mb-16">
            <span class="label">主导产业</span>
            <el-input v-model="form.yqjbxx.zdcy" placeholder="请输入"
                      @input="event => handleNumericInput(form.yqjbxx, 'zdcy', event)" style="width: 340px;"></el-input>
            <span class="unit"></span>
          </div>
          <div class="item-row mb-16">
            <span class="label">投产企业数量</span>
            <el-input v-model="form.yqjbxx.tcqysl" placeholder="请输入"
                      @input="event => handleNumericInput(form.yqjbxx, 'tcqysl', event)" style="width: 340px;"></el-input>
            <span class="unit">个</span>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">四、企业所属产业分布</h3>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">不同行业的企业所属产业分布<span class="tips">（建议不超过7个行业）</span></div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.qysscyfb" :columns="tableColumns.qysscyfb">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'industry'">
                  <div class="row-title pointer clearfix" v-if="scope.row.industry !== '' && !scope.row.$isFocused" @dblclick="$set(scope.row, '$isFocused', true)">
                    {{ scope.row.industry }} <i class="fr icon-delete icon17 pointer"
                                                @click="form.qysscyfb.splice(scope.$index, 1)"></i></div>
                  <el-input v-else v-model="scope.row.industry" placeholder="请输入行业名称"
                            @focus="$set(scope.row, '$isFocused', true)" @blur="$set(scope.row, '$isFocused', false)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'enterprisesNum'">
                  <el-input v-model="scope.row.enterprisesNum" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'enterprisesNum', event)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'totalAnnualValue'">
                  <el-input v-model="scope.row.totalAnnualValue" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'totalAnnualValue', event)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'totalLandArea'">
                  <el-input v-model="scope.row.totalLandArea" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'totalLandArea', event)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'averageValuePerAcre'">
                  <el-input v-model="scope.row.averageValuePerAcre" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'averageValuePerAcre', event)"></el-input>
                </template>
              </template>
              <div class="table-add" slot="suffix">
                <i class="icon-add icon17 pointer"
                   @click="form.qysscyfb.push({industry: '', enterprisesNum: null, totalAnnualValue: null, totalLandArea: null,averageValuePerAcre:null})"></i>
              </div>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">五、能耗</h3>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">1、水消耗环比分析</div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.nh.sxhhbfx" :columns="tableColumns.sxhhbfx" cell-width="120px" :cell-auto-width="false" header-width="152px">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'month'">
                  <div class="row-title">
                    {{ scope.row.month }}
                  </div>
                </template>
                <template v-else-if="scope.prop === 'waterConsumption'">
                  <el-input v-model="scope.row.waterConsumption" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'waterConsumption', event)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'chainRatio'">
                  <el-input v-model="scope.row.chainRatio" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'chainRatio', event)"></el-input>
                </template>
              </template>
            </horizontal-table>
          </div>
        </div>
      </div>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">2、电消耗环比分析</div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.nh.dxhhbfx" :columns="tableColumns.dxhhbfx" cell-width="120px" :cell-auto-width="false" header-width="152px">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'month'">
                  <div class="row-title">
                    {{ scope.row.month }}
                  </div>
                </template>
                <template v-else-if="scope.prop === 'electricityConsumption'">
                  <el-input v-model="scope.row.electricityConsumption" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'electricityConsumption', event)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'chainRatio'">
                  <el-input v-model="scope.row.chainRatio" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'chainRatio', event)"></el-input>
                </template>
              </template>
            </horizontal-table>
          </div>
        </div>
      </div>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">3、煤消耗环比分析</div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.nh.mxhhbfx" :columns="tableColumns.mxhhbfx" cell-width="120px" :cell-auto-width="false" header-width="152px">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'month'">
                  <div class="row-title">
                    {{ scope.row.month }}
                  </div>
                </template>
                <template v-else-if="scope.prop === 'mediumUsed'">
                  <el-input v-model="scope.row.mediumUsed" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'mediumUsed', event)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'chainRatio'">
                  <el-input v-model="scope.row.chainRatio" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'chainRatio', event)"></el-input>
                </template>
              </template>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pb-20 pl-25 btn_box">
      <span class="btn blue" @click="save">确定</span>
      <span class="btn" @click="resetForm">重置</span>
    </div>
  </div>
</template>

<style lang="scss" scoped src="@/styles/common.scss"></style>
<style scoped lang="scss">
.overview-park {
  > .item {
    border-bottom: 1px solid #EDF1F4;

    &:nth-last-child(-n+3) {
      border-bottom: none;
    }
  }
}

.headline {
  font-weight: bold;
  font-size: 14px;
  color: #242D33;
  line-height: 36px;
}

.subtitle, .label, .unit {
  font-weight: 400;
  font-size: 14px;
  color: #242D33;
  line-height: 36px;

  .tips {
    color: #6C7880;
  }
}

.label {
  font-size: 12px;
  margin-right: 12px;
  display: inline-block;
  width: 74px;
  text-align: right;
}

.unit {
  font-size: 12px;
  margin-left: 12px;
}

.row-title {
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 20px;

  .icon-delete {
    position: absolute;
    right: 0;
  }

  &.center {
    text-align: center;
    justify-content: center;
    padding-right: 0;
  }
}

.add-btn {
  display: inline-flex;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border: 1px solid #3984F5;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #3984F5;
  cursor: pointer;
  padding: 0 16px;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  &:hover {
    background-color: #3984F5;
    color: #fff;

    .icon-add {
      background-image: url("~@/assets/icon/add_white_icon@2x.png");
    }
  }
}

.row-year {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-delete {
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-add {
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.col-2 {
  display: grid;
  grid-template-columns: 500px minmax(500px, 1fr);
  grid-column-gap: 20px;
}

.btn_box {
  margin-top: 0;
}
</style>