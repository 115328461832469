<script>
import titleDom from "@/components/common/titleDom.vue";
import overviewPark from "@/views/system/organization/dataReporting/components/overviewPark.vue";
import governmentAffairsOffice from "@/views/system/organization/dataReporting/components/governmentAffairsOffice.vue";
import investmentManagement from "@/views/system/organization/dataReporting/components/investmentManagement.vue";
import projectManagement from "@/views/system/organization/dataReporting/components/projectManagement.vue";
import bigDataPark from "@/views/system/organization/dataReporting/components/bigDataPark.vue";
import corporateProfile from "@/views/system/organization/dataReporting/components/corporateProfile.vue";
import parkPortrait from "./components/parkPortrait";
import industryPortrait from "./components/industryPortrait";

export default {
  name: "index",
  components: {
    titleDom,
    overviewPark,
    governmentAffairsOffice,
    investmentManagement,
    projectManagement,
    bigDataPark,
    corporateProfile,
    parkPortrait,
    industryPortrait
  },
  data() {
    return {
      tabsIndex: {
        prent: 'decision',
        child: 0
      },
      editStatus: 0, // 编辑状态，用来判断是否提示保存 0：未编辑，1：编辑中，2：已保存
    };
  },
  provide() {
    return {
      updateEditStatus: this.updateEditStatusFunc // 返回一个包含方法的对象
    };
  },
  created() {
    this.updateEditStatusFunc(0);
    window.onbeforeunload = () => {
      if (this.editStatus === 1) {
        return '当前未保存，确定离开吗？';
      }
    };
  },
  methods: {
    beforeSwitchPrentTab() {
      if (this.editStatus !== 1) {
        this.tabsIndex.child = 0;
        this.updateEditStatusFunc(0);
        return true;
      } else {
        return new Promise((resolve, reject) => {
          this.$confirm('当前页面内容暂未保存，确定切换标签吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.tabsIndex.child = 0;
            this.updateEditStatusFunc(0);
            // 用户确认切换
            resolve();
          }).catch(() => {
            // 用户取消切换
            reject();
          });
        });
      }
    },
    switchChildTab(index) {
      if (this.editStatus !== 1) {
        this.tabsIndex.child = index;
      } else {
        this.$confirm('当前页面内容暂未保存，确定切换标签吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.tabsIndex.child = index;
          this.updateEditStatusFunc(0);
        })
      }
    },
    updateEditStatusFunc(editStatus) {
      this.editStatus = editStatus;
      console.log('updateEditStatusFunc', editStatus);
      this.$store.dispatch('SET_EDIT_STATUS', editStatus);
    }
  }
}
</script>

<template>
  <div class="report-container container">
    <title-dom class="mb-20" :title="'可视化数据填报'" :isShowLine="false" :distanceLeft="'-20px'"></title-dom>
    <el-tabs v-model="tabsIndex.prent" :before-leave="beforeSwitchPrentTab">
      <el-tab-pane label="园区决策平台" name="decision">
        <div class="report-content" v-if="tabsIndex.prent === 'decision'">
          <div class="header">
            <span class="header-item" :class="{active: tabsIndex.child === 0}" @click="switchChildTab(0)">园区总览</span>
            <span class="header-item" :class="{active: tabsIndex.child === 1}" @click="switchChildTab(1)">政务办公</span>
            <span class="header-item" :class="{active: tabsIndex.child === 2}" @click="switchChildTab(2)">招商管理</span>
            <span class="header-item" :class="{active: tabsIndex.child === 3}" @click="switchChildTab(3)">项目管理</span>
          </div>
          <div class="content">
            <div class="content-item" v-if="tabsIndex.child === 0">
  <!--            园区总览容器-->
              <overview-park></overview-park>
            </div>
            <div class="content-item" v-if="tabsIndex.child === 1">
              <!--            政务办公容器-->
              <government-affairs-office></government-affairs-office>
            </div>
            <div class="content-item" v-if="tabsIndex.child === 2">
              <!--            招商管理容器-->
              <investment-management></investment-management>
            </div>
            <div class="content-item" v-if="tabsIndex.child === 3">
              <!--            项目管理容器-->
              <project-management></project-management>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="园区服务平台" name="service">
        <div class="report-content" v-if="tabsIndex.prent === 'service'">
          <div class="header">
            <span class="header-item" :class="{active: tabsIndex.child === 0}" @click="switchChildTab(0)">园区大数据</span>
            <span class="header-item" :class="{active: tabsIndex.child === 1}" @click="switchChildTab(1)">企业画像</span>
            <span class="header-item" :class="{active: tabsIndex.child === 2}" @click="switchChildTab(2)">园区画像</span>
            <span class="header-item" :class="{active: tabsIndex.child === 3}" @click="switchChildTab(3)">产业画像</span>
          </div>
          <div class="content">
            <div class="content-item" v-if="tabsIndex.child === 0">
              <!--            园区大数据容器-->
              <big-data-park></big-data-park>
            </div>
            <div class="content-item" v-if="tabsIndex.child === 1">
              <!--            企业画像容器-->
              <corporate-profile></corporate-profile>
            </div>
            <div class="content-item" v-if="tabsIndex.child === 2">
              <!--            园区画像容器-->
              <park-portrait></park-portrait>
            </div>
            <div class="content-item" v-if="tabsIndex.child === 3">
              <!--            产业画像容器-->
              <industry-portrait></industry-portrait>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<style lang="scss" scoped src="@/styles/common.scss"></style>
<style scoped lang="scss">
.container {
  margin: 18px;
  padding: 30px 20px;
  width: calc(100% - 76px);
  min-height: calc(100% - 96px);
  background: #FFFFFF;

  .report-content {
    padding-top: 14px;

    .header {
      > span {
        box-sizing: border-box;
        display: inline-block;
        width: 124px;
        height: 34px;
        line-height: 34px;
        background: #EEF1F3;
        border-radius: 4px;
        font-weight: 400;
        font-size: 14px;
        color: #6C7880;
        text-align: center;
        margin-right: 20px;
        cursor: pointer;

        &.active {
          background: #3984F5;
          color: #FFFFFF;
        }
      }
    }
  }
}
::v-deep .el-tabs__nav-wrap::after {
  height: 1px !important;
}

::v-deep .el-tabs__active-bar {
  height: 3px !important;
}
</style>