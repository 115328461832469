<template>
  <div class="horizontal-table"
       :style="{'--header-width': headerWidth, '--cell-width': cellWidth, '--cell-height': cellHeight, '--table-height': tableHeight, '--cell-auto-width': cellAutoWidth}">
    <div class="table-container" ref="tableContainer">
      <div class="table-header">
        <div class="table-item" ref="tableHeader" v-for="num in numberOfColumns" :key="num">
          <template v-for="(column, cIndex) in columns">
            <div class="table-cell table-header" :key="cIndex">
              <slot name="table-header" :column="column" :label="column.label" :cIndex="0" :rIndex="cIndex">
                {{ column.label }}
              </slot>
            </div>
          </template>
        </div>
      </div>
      <div class="table-body">
        <div class="table-item" v-for="(item, index) in data" :key="index">
          <div class="table-cell" :class="[cellAutoWidth ? '' : 'cell-fixed-width']" v-for="(column, cIndex) in columns" :key="cIndex">
            <slot name="table-cell" :row="item" :$index="index" :cIndex="index + 1" :rIndex="cIndex" :prop="column.prop"
                  :column="column" :$isFocused="false"
                  :value="item[column.prop]">{{ item[column.prop] }}
            </slot>
          </div>
        </div>
        <div :style="{height: tableItemHeight}">
          <slot name="suffix"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorizontalTable',
  props: {
    data: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    headerWidth: {
      type: String,
      default: '140px'
    },
    cellWidth: {
      type: String,
      default: '180px'
    },
    cellHeight: {
      type: String,
      default: '60px'
    },
    tableHeight: {
      type: String,
      default: '200px'
    },
    cellAutoWidth: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    data: {
      handler(newVal, oldVal) {
        this.numberOfColumns = 1;
        this.$nextTick(() => {
          const tableHeight =this.$refs.tableContainer.getBoundingClientRect().height;
          const tableItemHeight = this.$refs.tableHeader[0].getBoundingClientRect().height;
          this.numberOfColumns = Math.ceil(tableHeight / tableItemHeight);
          this.tableItemHeight = tableItemHeight + 'px';
          console.log(tableHeight, tableItemHeight, this.numberOfColumns, 111111111111);
        });
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      numberOfColumns: 1,
      tableItemHeight: ''
    }
  }
}
</script>

<style scoped lang="scss">
.horizontal-table {
  box-sizing: border-box;
  width: 100%;

  .table-container {
    box-sizing: border-box;
    width: 100%;
    display: grid;
    grid-template-columns: var(--header-width) 1fr;
  }

  .table-header {
    box-sizing: border-box;

  }

  .table-body {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;


    .table-item {
      box-sizing: border-box;
      min-width: var(--cell-width);
      position: relative;
    }
  }

    .table-cell {
      box-sizing: border-box;
      margin: 0 -1px -1px 0; //边框线重合
      font-weight: 400;
      font-size: 12px;
      color: #444F57;
      line-height: 36px;
      padding: 11.5px 15px;
      border: 1px solid #DBE1E5;
      min-width: var(--cell-width);
      height: var(--cell-height);

      &.cell-fixed-width {
        width: calc(var(--cell-width) + 1px);
      }

      &.table-header {
        background: #F6F7F9;
        min-width: var(--header-width);
      }
    }
}


/* 其他样式可以根据需要进行添加 */
</style>
