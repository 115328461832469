<script>
import HorizontalTable from "@/views/system/organization/dataReporting/components/common/horizontal-table/HorizontalTable.vue";

const DEFAULT_FORM = {
  lcztfx: {
    dbsl: null,
    zcsl: null,
    bjsl: null,
    cqsl: null,
    yjsl: null
  },
  cylc: [
    {
      processName: '物品领用',
      number: null
    },
    {
      processName: '请假申请单',
      number: null
    },
    {
      processName: '用章申请',
      number: null
    },
    {
      processName: '用车申请',
      number: null
    },
    {
      processName: '租车申请',
      number: null
    }
  ],
  fwqktj: [
    {
      month: '1月',
      number: null
    },
    {
      month: '2月',
      number: null
    },
    {
      month: '3月',
      number: null
    },
    {
      month: '4月',
      number: null
    },
    {
      month: '5月',
      number: null
    },
    {
      month: '6月',
      number: null
    },
    {
      month: '7月',
      number: null
    },
    {
      month: '8月',
      number: null
    },
    {
      month: '9月',
      number: null
    },
    {
      month: '10月',
      number: null
    },
    {
      month: '11月',
      number: null
    },
    {
      month: '12月',
      number: null
    }
  ],
  dbrwlxtj: [
    {
      taskType: '重点工作',
      number: null,
      proportion: null
    },
    {
      taskType: '来办文件',
      number: null,
      proportion: null
    },
    {
      taskType: '领导交办',
      number: null,
      proportion: null
    },
    {
      taskType: '领导批示',
      number: null,
      proportion: null
    },
    {
      taskType: '日常工作',
      number: null,
      proportion: null
    }
  ],
  gglxtj: [
    {
      announcementType: '行政',
      number: null
    },
    {
      announcementType: '财务',
      number: null
    },
    {
      announcementType: '人事',
      number: null
    },
    {
      announcementType: '营销',
      number: null
    },
    {
      announcementType: '其他',
      number: null
    }
  ]
}

export default {
  name: "governmentAffairsOffice", // 政务办公
  inject: ["updateEditStatus"], // 注入updateEditStatus编辑状态，用来判断是否提示保存 0：未编辑，1：编辑中，2：已保存方法
  components: {
    HorizontalTable
  },
  data() {
    return {
      id: null,
      loading: false,
      // 是否重置表单
      isReset: false,
      isEdit: false,
      form: JSON.parse(JSON.stringify(DEFAULT_FORM)),
      tableColumns: {
        cylc: [
          {
            label: '数量/流程名称',
            prop: 'processName'
          },
          {
            label: '数量',
            prop: 'number'
          }
        ],
        fwqktj: [
          {
            label: '数量/月份',
            prop: 'month'
          },
          {
            label: '数量（次）',
            prop: 'number'
          }
        ],
        dbrwlxtj: [
          {
            label: '数量/任务类型',
            prop: 'taskType'
          },
          {
            label: '数量',
            prop: 'number'
          },
          {
            label: '占比（%）',
            prop: 'proportion'
          }
        ],
        gglxtj: [
          {
            label: '数量/公告类型',
            prop: 'announcementType'
          },
          {
            label: '数量（个）',
            prop: 'number'
          }
        ]
      }
    }
  },
  watch: {
    form: {
      handler(val, oldVal) {
        if (!this.isReset && !this.isEdit) {
          this.updateEditStatus(1)
        } else if (this.isReset) {
          this.isReset = false;
          this.updateEditStatus(0)
        } else if (this.isEdit) {
          this.isEdit = false;
          this.updateEditStatus(2)
        }
      },
      deep: true
    }
  },
  mounted() {
    this.getDetail('1-2')
  },
  methods: {
    getDetail(step) {
      const data = {
        organize_id: this.$route.query.id,
        first_level: step.split('-')[0],
        second_level: step.split('-')[1]
      }
      this.loading = true;
      this.$api.dataReportingDetail(data).then(res => {
        this.loading = false;
        this.id = res.data.id || null;
        this.isReset = true;
        this.loading = false;
        if (res.data.content) {
          this.form = JSON.parse(res.data.content)
        }
      })
    },
    save() {
      const params = {
        id: this.id,
        organize_id: this.$route.query.id,
        first_level: 1,
        second_level: 2,
        content: JSON.stringify(this.form)
      }
      this.$api.saveDataPark(params).then(res => {
        this.id = res.data.id;
        this.form = JSON.parse(JSON.parse(res.data.content));
        this.isEdit = true;
        this.$message.success('保存成功')
        this.updateEditStatus(2)
      })
    },
    handleNumericInput(obj, prop, event) {
      if (obj && prop in obj) {
        obj[prop] = event.replace(/[^\d]/g, '');
      }
    },
    // 重置表单
    resetForm() {
      this.$confirm('确定清空当前页面的数据内容吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.form = JSON.parse(JSON.stringify(DEFAULT_FORM))
        this.isReset = true
      })
    }
  }
}
</script>

<template>
  <div class="overview-park pt-10 pb-20" v-loading="loading">
    <div class="item pt-20 pb-20">
      <h3 class="headline">一、流程状态分析</h3>
      <div class="item-content pl-25">
        <div class="content col-2 pt-20">
          <div class="item-row mb-16">
            <span class="label">待办数量</span>
            <el-input v-model="form.lcztfx.dbsl" placeholder="请输入"
                      @input="event => handleNumericInput(form.lcztfx, 'dbsl', event)" style="width: 340px;"></el-input>
            <span class="unit">个</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">正常数量</span>
            <el-input v-model="form.lcztfx.zcsl" placeholder="请输入"
                      @input="event => handleNumericInput(form.lcztfx, 'zcsl', event)" style="width: 340px;"></el-input>
            <span class="unit">个</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">办结数量</span>
            <el-input v-model="form.lcztfx.bjsl" placeholder="请输入"
                      @input="event => handleNumericInput(form.lcztfx, 'bjsl', event)" style="width: 340px;"></el-input>
            <span class="unit">个</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">超期数量</span>
            <el-input v-model="form.lcztfx.cqsl" placeholder="请输入"
                      @input="event => handleNumericInput(form.lcztfx, 'cqsl', event)" style="width: 340px;"></el-input>
            <span class="unit">个</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">预警数量</span>
            <el-input v-model="form.lcztfx.yjsl" placeholder="请输入"
                      @input="event => handleNumericInput(form.lcztfx, 'yjsl', event)" style="width: 340px;"></el-input>
            <span class="unit">个</span>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">二、常用流程Top5</h3>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">常用流程的使用次数<span class="tips">（需添加5个流程）</span></div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.cylc" :columns="tableColumns.cylc">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'processName'">
                  <div class="row-title pointer clearfix" v-if="scope.row.processName !== '' && !scope.row.$isFocused" @dblclick="$set(scope.row, '$isFocused', true)">
                    {{ scope.row.processName }} <i class="fr icon-delete icon17 pointer"
                                                   @click="form.cylc.splice(scope.$index, 1)"></i></div>
                  <el-input v-else v-model="scope.row.processName" placeholder="请输入流程名称"
                            @focus="$set(scope.row, '$isFocused', true)" @blur="$set(scope.row, '$isFocused', false)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'number'">
                  <el-input v-model="scope.row.number" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'number', event)"></el-input>
                </template>
              </template>
              <div class="table-add" slot="suffix">
                <i class="icon-add icon17 pointer"
                   @click="form.cylc.push({processName: '', number: null})"></i>
              </div>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">三、发文情况统计</h3>
      <div class="item-content pl-25">
        <div class="content">
          <div class="item-row mb-16 pt-20">
            <horizontal-table :data="form.fwqktj" :columns="tableColumns.fwqktj" cell-width="120px" :cell-auto-width="false">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'month'">
                  <div class="row-title">
                    {{ scope.row.month }}
                  </div>
                </template>
                <template v-else-if="scope.prop === 'number'">
                  <el-input v-model="scope.row.number" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'number', event)"></el-input>
                </template>
              </template>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">四、督办任务类型统计</h3>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">督办任务类型统计<span class="tips">（建议不超过7个类型）</span></div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.dbrwlxtj" :columns="tableColumns.dbrwlxtj">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'taskType'">
                  <div class="row-title pointer clearfix" v-if="scope.row.taskType !== '' && !scope.row.$isFocused" @dblclick="$set(scope.row, '$isFocused', true)">
                    {{ scope.row.taskType }} <i class="fr icon-delete icon17 pointer"
                                                   @click="form.dbrwlxtj.splice(scope.$index, 1)"></i></div>
                  <el-input v-else v-model="scope.row.taskType" placeholder="请输入任务类型"
                            @focus="$set(scope.row, '$isFocused', true)" @blur="$set(scope.row, '$isFocused', false)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'number'">
                  <el-input v-model="scope.row.number" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'number', event)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'proportion'">
                  <el-input v-model="scope.row.proportion" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'proportion', event)"></el-input>
                </template>
              </template>
              <div class="table-add" slot="suffix">
                <i class="icon-add icon17 pointer"
                   @click="form.dbrwlxtj.push({taskType: '', number: null, proportion: null})"></i>
              </div>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">五、公告类型统计</h3>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">公告类型统计<span class="tips">（建议不超过7个类型）</span></div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.gglxtj" :columns="tableColumns.gglxtj">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'announcementType'">
                  <div class="row-title pointer clearfix" v-if="scope.row.announcementType !== '' && !scope.row.$isFocused" @dblclick="$set(scope.row, '$isFocused', true)">
                    {{ scope.row.announcementType }} <i class="fr icon-delete icon17 pointer"
                                                   @click="form.gglxtj.splice(scope.$index, 1)"></i></div>
                  <el-input v-else v-model="scope.row.announcementType" placeholder="请输入公告类型"
                            @focus="$set(scope.row, '$isFocused', true)" @blur="$set(scope.row, '$isFocused', false)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'number'">
                  <el-input v-model="scope.row.number" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'number', event)"></el-input>
                </template>
              </template>
              <div class="table-add" slot="suffix">
                <i class="icon-add icon17 pointer"
                   @click="form.gglxtj.push({announcementType: '', number: null})"></i>
              </div>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pb-20 pl-25 btn_box">
      <span class="btn blue" @click="save">确定</span>
      <span class="btn" @click="resetForm">重置</span>
    </div>
  </div>
</template>

<style lang="scss" scoped src="@/styles/common.scss"></style>
<style scoped lang="scss">
.overview-park {
  > .item {
    border-bottom: 1px solid #EDF1F4;

    &:nth-last-child(-n+3) {
      border-bottom: none;
    }
  }
}

.headline {
  font-weight: bold;
  font-size: 14px;
  color: #242D33;
  line-height: 36px;
}

.subtitle, .label, .unit {
  font-weight: 400;
  font-size: 14px;
  color: #242D33;
  line-height: 36px;

  .tips {
    color: #6C7880;
  }
}

.label {
  font-size: 12px;
  margin-right: 12px;
  display: inline-block;
  width: 52px;
  text-align: right;
}

.unit {
  font-size: 12px;
  margin-left: 12px;
}

.row-title {
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 20px;

  .icon-delete {
    position: absolute;
    right: 0;
  }

  &.center {
    text-align: center;
    justify-content: center;
    padding-right: 0;
  }
}

.table-add {
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.col-2 {
  display: grid;
  grid-template-columns: 500px minmax(500px, 1fr);
  grid-column-gap: 20px;
}

.btn_box {
  margin-top: 0;
}
</style>