import { render, staticRenderFns } from "./bigDataPark.vue?vue&type=template&id=2b692864&scoped=true&"
import script from "./bigDataPark.vue?vue&type=script&lang=js&"
export * from "./bigDataPark.vue?vue&type=script&lang=js&"
import style0 from "@/styles/common.scss?vue&type=style&index=0&id=2b692864&prod&lang=scss&scoped=true&"
import style1 from "./bigDataPark.vue?vue&type=style&index=1&id=2b692864&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b692864",
  null
  
)

export default component.exports