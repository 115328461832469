<script>
import HorizontalTable from "@/views/system/organization/dataReporting/components/common/horizontal-table/HorizontalTable.vue";

const DEFAULT_FORM = {
  ljzsqk: [
    {
      investmentType: '累计签约',
      number: null,
      proportion: null
    },
    {
      investmentType: '累计关闭',
      number: null,
      proportion: null
    }
  ],
  xmldt: [
    {
      projectType: '有效信息',
      number: null
    },
    {
      projectType: '在谈项目',
      number: null
    },
    {
      projectType: '拟签项目',
      number: null
    },
    {
      projectType: '合同签批',
      number: null
    },
    {
      projectType: '项目落地',
      number: null
    }
  ],
  zsyzqk: {
    zsxm: null,
    zsxmwcl: null,
    xyyzje: null,
    xyyzjewcl: null,
  },
  bnqyqk: [
    {
      industry: '精致茶制业',
      number: null,
      proportion: null
    },
    {
      industry: '精致咖啡制业',
      number: null,
      proportion: null
    },
    {
      industry: '绿色食品',
      number: null,
      proportion: null
    },
    {
      industry: '酒/饮料',
      number: null,
      proportion: null
    },
    {
      industry: '其他',
      number: null,
      proportion: null
    }
  ],
  xmqyqk: [
    {
      projectType: '租赁项目',
      number: null
    },
    {
      projectType: '购地项目',
      number: null
    },
    {
      projectType: '本年租赁',
      number: null
    },
    {
      projectType: '本年购地',
      number: null
    }
  ],
  dqyqk: {
    nqyxm: null,
    nqyxmzb: null,
    ztxm: null,
    ztxmzb: null,
  },
  byqyqk: {
    byqy: null,
    byqyzb: null,
    bytz: null,
    bytzzb: null,
  },
  xmlyzb: [
    {
      projectSource: '自主招商',
      number: null,
      proportion: null
    },
    {
      projectSource: '主动上门',
      number: null,
      proportion: null
    },
    {
      projectSource: '企业介绍',
      number: null,
      proportion: null
    },
    {
      projectSource: '市级部门',
      number: null,
      proportion: null
    },
    {
      projectSource: '其他',
      number: null,
      proportion: null
    }
  ],
  lydqzb: [
    {
      sourceRegion: '长三角',
      number: null,
      proportion: null
    },
    {
      sourceRegion: '珠三角',
      number: null,
      proportion: null
    },
    {
      sourceRegion: '京津冀',
      number: null,
      proportion: null
    },
    {
      sourceRegion: '成渝双城',
      number: null,
      proportion: null
    },
    {
      sourceRegion: '市外其他',
      number: null,
      proportion: null
    }
  ]
}

export default {
  name: "InvestmentManagement", // 招商管理
  inject: ["updateEditStatus"], // 注入updateEditStatus编辑状态，用来判断是否提示保存 0：未编辑，1：编辑中，2：已保存方法
  components: {
    HorizontalTable
  },
  data() {
    return {
      id: null,
      loading: false,
      // 是否重置表单
      isReset: false,
      isEdit: false,
      form: JSON.parse(JSON.stringify(DEFAULT_FORM)),
      tableColumns: {
        ljzsqk: [
          {
            label: '数量/招商类型',
            prop: 'investmentType'
          },
          {
            label: '数量（个）',
            prop: 'number'
          },
          {
            label: '占比（%）',
            prop: 'proportion'
          }
        ],
        xmldt: [
          {
            label: '数量/项目类型',
            prop: 'projectType'
          },
          {
            label: '数量（个）',
            prop: 'number'
          }
        ],
        bnqyqk: [
          {
            label: '数量/行业',
            prop: 'industry'
          },
          {
            label: '签约数量',
            prop: 'number'
          },
          {
            label: '占比（%）',
            prop: 'proportion'
          }
        ],
        xmqyqk: [
          {
            label: '数量/项目类型',
            prop: 'projectType'
          },
          {
            label: '数量（个）',
            prop: 'number'
          }
        ],
        xmlyzb: [
          {
            label: '数量/项目来源',
            prop: 'projectSource'
          },
          {
            label: '数量（个）',
            prop: 'number'
          },
          {
            label: '占比（%）',
            prop: 'proportion'
          }
        ],
        lydqzb: [
          {
            label: '数量/来源地区',
            prop: 'sourceRegion'
          },
          {
            label: '数量（个）',
            prop: 'number'
          },
          {
            label: '占比（%）',
            prop: 'proportion'
          }
        ],
      }
    }
  },
  watch: {
    form: {
      handler(val, oldVal) {
        if (!this.isReset && !this.isEdit) {
          this.updateEditStatus(1)
        } else if (this.isReset) {
          this.isReset = false;
          this.updateEditStatus(0)
        } else if (this.isEdit) {
          this.isEdit = false;
          this.updateEditStatus(2)
        }
      },
      deep: true
    }
  },
  mounted() {
    this.getDetail('1-3')
  },
  methods: {
    getDetail(step) {
      const data = {
        organize_id: this.$route.query.id,
        first_level: step.split('-')[0],
        second_level: step.split('-')[1]
      }
      this.loading = true;
      this.$api.dataReportingDetail(data).then(res => {
        this.loading = false;
        this.id = res.data.id || null;
        this.isReset = true;
        this.loading = false;
        if (res.data.content) {
          this.form = JSON.parse(res.data.content)
        }
      })
    },
    save() {
      const params = {
        id: this.id,
        organize_id: this.$route.query.id,
        first_level: 1,
        second_level: 3,
        content: JSON.stringify(this.form)
      }
      this.$api.saveDataPark(params).then(res => {
        this.id = res.data.id;
        this.form = JSON.parse(JSON.parse(res.data.content));
        this.isEdit = true;
        this.$message.success('保存成功')
        this.updateEditStatus(2)
      })
    },
    handleNumericInput(obj, prop, event) {
      if (obj && prop in obj) {
        obj[prop] = event.replace(/[^\d]/g, '');
      }
    },
    // 重置表单
    resetForm() {
      this.$confirm('确定清空当前页面的数据内容吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.form = JSON.parse(JSON.stringify(DEFAULT_FORM))
        this.isReset = true
      })
    }
  }
}
</script>

<template>
  <div class="overview-park pt-10 pb-20" v-loading="loading">
    <div class="item pt-20 pb-20">
      <h3 class="headline">一、累计招商情况</h3>
      <div class="item-content pl-25">
        <div class="content">
          <div class="item-row mb-16 pt-20">
            <horizontal-table :data="form.ljzsqk" :columns="tableColumns.ljzsqk" cell-width="120px">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'investmentType'">
                  <div class="row-title">
                    {{ scope.row.investmentType }}
                  </div>
                </template>
                <template v-else-if="scope.prop === 'number'">
                  <el-input v-model="scope.row.number" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'number', event)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'proportion'">
                  <el-input v-model="scope.row.proportion" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'proportion', event)"></el-input>
                </template>
              </template>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">二、项目漏斗图</h3>
      <div class="item-content pl-25">
        <div class="content">
          <div class="item-row mb-16 pt-20">
            <horizontal-table :data="form.xmldt" :columns="tableColumns.xmldt" cell-width="120px">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'projectType'">
                  <div class="row-title">
                    {{ scope.row.projectType }}
                  </div>
                </template>
                <template v-else-if="scope.prop === 'number'">
                  <el-input v-model="scope.row.number" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'number', event)"></el-input>
                </template>
              </template>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">三、招商引资情况</h3>
      <div class="item-content pl-25">
        <div class="content col-2 pt-20">
          <div class="item-row mb-16">
            <span class="label">招商项目</span>
            <el-input v-model="form.zsyzqk.zsxm" placeholder="请输入"
                      @input="event => handleNumericInput(form.zsyzqk, 'zsxm', event)" style="width: 340px;"></el-input>
            <span class="unit">个</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">完成量</span>
            <el-input v-model="form.zsyzqk.zsxmwcl" placeholder="请输入"
                      @input="event => handleNumericInput(form.zsyzqk, 'zsxmwcl', event)" style="width: 340px;"></el-input>
            <span class="unit">%</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">协议引资金额</span>
            <el-input v-model="form.zsyzqk.xyyzje" placeholder="请输入"
                      @input="event => handleNumericInput(form.zsyzqk, 'xyyzje', event)" style="width: 340px;"></el-input>
            <span class="unit">亿元</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">完成量</span>
            <el-input v-model="form.zsyzqk.xyyzjewcl" placeholder="请输入"
                      @input="event => handleNumericInput(form.zsyzqk, 'xyyzjewcl', event)" style="width: 340px;"></el-input>
            <span class="unit">%</span>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">四、本年签约情况</h3>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">本年签约情况<span class="tips">（建议不超过7个行业）</span></div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.bnqyqk" :columns="tableColumns.bnqyqk">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'industry'">
                  <div class="row-title pointer clearfix" v-if="scope.row.industry !== '' && !scope.row.$isFocused" @dblclick="$set(scope.row, '$isFocused', true)">
                    {{ scope.row.industry }} <i class="fr icon-delete icon17 pointer"
                                                @click="form.bnqyqk.splice(scope.$index, 1)"></i></div>
                  <el-input v-else v-model="scope.row.industry" placeholder="请输入行业名称"
                            @focus="$set(scope.row, '$isFocused', true)" @blur="$set(scope.row, '$isFocused', false)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'number'">
                  <el-input v-model="scope.row.number" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'number', event)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'proportion'">
                  <el-input v-model="scope.row.proportion" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'proportion', event)"></el-input>
                </template>
              </template>
              <div class="table-add" slot="suffix">
                <i class="icon-add icon17 pointer"
                   @click="form.bnqyqk.push({industry: '', number: null, proportion: null})"></i>
              </div>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">五、项目签约情况</h3>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">不同类型项目的签约情况统计<span class="tips">（建议不超过7个类型）</span></div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.xmqyqk" :columns="tableColumns.xmqyqk">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'projectType'">
                  <div class="row-title pointer clearfix" v-if="scope.row.projectType !== '' && !scope.row.$isFocused" @dblclick="$set(scope.row, '$isFocused', true)">
                    {{ scope.row.projectType }} <i class="fr icon-delete icon17 pointer"
                                                @click="form.xmqyqk.splice(scope.$index, 1)"></i></div>
                  <el-input v-else v-model="scope.row.projectType" placeholder="请输入项目类型"
                            @focus="$set(scope.row, '$isFocused', true)" @blur="$set(scope.row, '$isFocused', false)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'number'">
                  <el-input v-model="scope.row.number" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'number', event)"></el-input>
                </template>
              </template>
              <div class="table-add" slot="suffix">
                <i class="icon-add icon17 pointer"
                   @click="form.xmqyqk.push({projectType: '', number: null})"></i>
              </div>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">六、待签约情况</h3>
      <div class="item-content pl-25">
        <div class="content col-2 pt-20">
          <div class="item-row mb-16">
            <span class="label">拟签约项目</span>
            <el-input v-model="form.dqyqk.nqyxm" placeholder="请输入"
                      @input="event => handleNumericInput(form.dqyqk, 'nqyxm', event)" style="width: 340px;"></el-input>
            <span class="unit">个</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">占比</span>
            <el-input v-model="form.dqyqk.nqyxmzb" placeholder="请输入"
                      @input="event => handleNumericInput(form.dqyqk, 'nqyxmzb', event)" style="width: 340px;"></el-input>
            <span class="unit">%</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">在谈项目</span>
            <el-input v-model="form.dqyqk.ztxm" placeholder="请输入"
                      @input="event => handleNumericInput(form.dqyqk, 'ztxm', event)" style="width: 340px;"></el-input>
            <span class="unit">个</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">占比</span>
            <el-input v-model="form.dqyqk.ztxmzb" placeholder="请输入"
                      @input="event => handleNumericInput(form.dqyqk, 'ztxmzb', event)" style="width: 340px;"></el-input>
            <span class="unit">%</span>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">七、本月签约情况</h3>
      <div class="item-content pl-25">
        <div class="content col-2 pt-20">
          <div class="item-row mb-16">
            <span class="label">本月签约</span>
            <el-input v-model="form.byqyqk.byqy" placeholder="请输入"
                      @input="event => handleNumericInput(form.byqyqk, 'byqy', event)" style="width: 340px;"></el-input>
            <span class="unit">个</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">占比</span>
            <el-input v-model="form.byqyqk.byqyzb" placeholder="请输入"
                      @input="event => handleNumericInput(form.byqyqk, 'byqyzb', event)" style="width: 340px;"></el-input>
            <span class="unit">%</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">本月投资</span>
            <el-input v-model="form.byqyqk.bytz" placeholder="请输入"
                      @input="event => handleNumericInput(form.byqyqk, 'bytz', event)" style="width: 340px;"></el-input>
            <span class="unit">亿元</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">占比</span>
            <el-input v-model="form.byqyqk.bytzzb" placeholder="请输入"
                      @input="event => handleNumericInput(form.byqyqk, 'bytzzb', event)" style="width: 340px;"></el-input>
            <span class="unit">%</span>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">八、签约项目来源分析</h3>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">1、项目来源占比<span class="tips">（建议不超过7个来源）</span></div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.xmlyzb" :columns="tableColumns.xmlyzb">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'projectSource'">
                  <div class="row-title pointer clearfix" v-if="scope.row.projectSource !== '' && !scope.row.$isFocused" @dblclick="$set(scope.row, '$isFocused', true)">
                    {{ scope.row.projectSource }} <i class="fr icon-delete icon17 pointer"
                                                @click="form.xmlyzb.splice(scope.$index, 1)"></i></div>
                  <el-input v-else v-model="scope.row.projectSource" placeholder="请输入来源名称"
                            @focus="$set(scope.row, '$isFocused', true)" @blur="$set(scope.row, '$isFocused', false)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'number'">
                  <el-input v-model="scope.row.number" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'number', event)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'proportion'">
                  <el-input v-model="scope.row.proportion" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'proportion', event)"></el-input>
                </template>
              </template>
              <div class="table-add" slot="suffix">
                <i class="icon-add icon17 pointer"
                   @click="form.xmlyzb.push({projectSource: '', number: null, proportion: null})"></i>
              </div>
            </horizontal-table>
          </div>
        </div>
      </div>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">2、来源地区占比<span class="tips">（建议不超过7个地区）</span></div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.lydqzb" :columns="tableColumns.lydqzb">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'sourceRegion'">
                  <div class="row-title pointer clearfix" v-if="scope.row.sourceRegion !== '' && !scope.row.$isFocused" @dblclick="$set(scope.row, '$isFocused', true)">
                    {{ scope.row.sourceRegion }} <i class="fr icon-delete icon17 pointer"
                                                @click="form.lydqzb.splice(scope.$index, 1)"></i></div>
                  <el-input v-else v-model="scope.row.sourceRegion" placeholder="请输入地区名称"
                            @focus="$set(scope.row, '$isFocused', true)" @blur="$set(scope.row, '$isFocused', false)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'number'">
                  <el-input v-model="scope.row.number" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'number', event)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'proportion'">
                  <el-input v-model="scope.row.proportion" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'proportion', event)"></el-input>
                </template>
              </template>
              <div class="table-add" slot="suffix">
                <i class="icon-add icon17 pointer"
                   @click="form.lydqzb.push({sourceRegion: '', number: null, proportion: null})"></i>
              </div>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pb-20 pl-25 btn_box">
      <span class="btn blue" @click="save">确定</span>
      <span class="btn" @click="resetForm">重置</span>
    </div>
  </div>
</template>

<style lang="scss" scoped src="@/styles/common.scss"></style>
<style scoped lang="scss">
.overview-park {
  > .item {
    border-bottom: 1px solid #EDF1F4;

    &:nth-last-child(-n+3) {
      border-bottom: none;
    }
  }
}

.headline {
  font-weight: bold;
  font-size: 14px;
  color: #242D33;
  line-height: 36px;
}

.subtitle, .label, .unit {
  font-weight: 400;
  font-size: 14px;
  color: #242D33;
  line-height: 36px;

  .tips {
    color: #6C7880;
  }
}

.label {
  font-size: 12px;
  margin-right: 12px;
  display: inline-block;
  width: 72px;
  text-align: right;
}

.unit {
  font-size: 12px;
  margin-left: 12px;
}

.row-title {
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 20px;

  .icon-delete {
    position: absolute;
    right: 0;
  }

  &.center {
    text-align: center;
    justify-content: center;
    padding-right: 0;
  }
}

.table-add {
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.col-2 {
  display: grid;
  grid-template-columns: 500px minmax(500px, 1fr);
  grid-column-gap: 20px;
}

.btn_box {
  margin-top: 0;
}
</style>