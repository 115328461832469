<script>
import HorizontalTable from "@/views/system/organization/dataReporting/components/common/horizontal-table/HorizontalTable.vue";
import HorizontalTableYear from "@/views/system/organization/dataReporting/components/common/horizontal-table/HorizontalTableYear.vue";
import EditTag from "@/components/common/EditTag.vue";

const DEFAULT_FORM = {
  cytd: {
    tjsj: {
      tdsl: null,
      jjfa: null
    },
    tdgjcfb: [
      {
        industry: '招人难',
        num: null,
      },
      {
        industry: '融资困难',
        num: null,
      },
      {
        industry: '经营成本高',
        num: null,
      },{
        industry: '管理不完善',
        num: null,
      },
      {
        industry: '营销不足',
        num: null,
      },
      {
        industry: '其他',
        num: null,
      },
    ]
  },
  cyhxsj: {
    cygjc: null,
    nsze: null,
    qyzs: null,
    cgze: null,
    czze: null
  },
  cysy:{
    zycgcp: [],
    wlfszb:[
      {
        industry: '公路运输',
        proportion:  null,
      },
      {
        industry: '铁路运输',
        proportion:  null,
      },
      {
        industry: '航空运输',
        proportion:  null,
      },
      {
        industry: '其他',
        proportion:  null,
      },
    ],
    wlcb:[
      {
        industry: '1月',
        amount:  null,
      },
      {
        industry: '2月',
        amount:  null,
      },
      {
        industry: '3月',
        amount:  null,
      },
      {
        industry: '4月',
        amount:  null,
      },
      {
        industry: '5月',
        amount:  null,
      },
      {
        industry: '6月',
        amount:  null,
      },
    ]
  },
  cyxy:{
    zyxscp:[],
    wlfszb:[
      {
        industry: '公路运输',
        proportion:  null,
      },
      {
        industry: '铁路运输',
        proportion:  null,
      },
      {
        industry: '航空运输',
        proportion:  null,
      },
      {
        industry: '其他',
        proportion:  null,
      },
    ],
    wlcb:[
      {
        industry: '1月',
        amount:  null,
      },
      {
        industry: '2月',
        amount:  null,
      },
      {
        industry: '3月',
        amount:  null,
      },
      {
        industry: '4月',
        amount:  null,
      },
      {
        industry: '5月',
        amount:  null,
      },
      {
        industry: '6月',
        amount:  null,
      },
    ]
  },
  rzxq: [
    {
      month: '1月',
      demandAmount: null,
      repaymentCycle: null
    },
    {
      month: '2月',
      demandAmount: null,
      repaymentCycle: null
    },
    {
      month: '3月',
      demandAmount: null,
      repaymentCycle: null
    },
    {
      month: '4月',
      demandAmount: null,
      repaymentCycle: null
    },
    {
      month: '5月',
      demandAmount: null,
      repaymentCycle: null
    },
    {
      month: '6月',
      demandAmount: null,
      repaymentCycle: null
    },
    {
      month: '7月',
      demandAmount: null,
      repaymentCycle: null
    },
    {
      month: '8月',
      demandAmount: null,
      repaymentCycle: null
    },
    {
      month: '9月',
      demandAmount: null,
      repaymentCycle: null
    },
    {
      month: '10月',
      demandAmount: null,
      repaymentCycle: null
    },
    {
      month: '11月',
      demandAmount: null,
      repaymentCycle: null
    },
    {
      month: '12月',
      demandAmount: null,
      repaymentCycle: null
    },
  ]
}

export default {
  name: "parkPortrait", // 园区画像
  inject: ["updateEditStatus"], // 注入updateEditStatus编辑状态，用来判断是否提示保存 0：未编辑，1：编辑中，2：已保存方法
  components: {
    HorizontalTable,
    HorizontalTableYear,
    EditTag
  },
  data() {
    return {
      id: null,
      loading:false,
      // 是否重置表单
      isReset: false,
      isEdit: false,
      form: JSON.parse(JSON.stringify(DEFAULT_FORM)),
      tableColumns: {
        tdgjcfb: [
          {
            label: '数量/关键词',
            prop: 'industry'
          },
          {
            label: '数量',
            prop: 'num'
          }
        ],
        swlfszb:[
          {
            label: '占比/类型',
            prop: 'industry'
          },
          {
            label: '占比（%）',
            prop: 'proportion'
          }
        ],
        swlcb:[
          {
            label: '金额/月份',
            prop: 'industry'
          },
          {
            label: '金额（万元）',
            prop: 'amount'
          }
        ],
        xwlfszb:[
          {
            label: '占比/类型',
            prop: 'industry'
          },
          {
            label: '占比（%）',
            prop: 'proportion'
          }
        ],
        xwlcb:[
          {
            label: '金额/月份',
            prop: 'industry'
          },
          {
            label: '金额（万元）',
            prop: 'amount'
          }
        ],
        rzxq:[
          {
            label: '金额/月份',
            prop: 'month'
          },
          {
            label: '需求金额（千万）',
            prop: 'demandAmount'
          },
          {
            label: '还款周期（月）',
            prop: 'repaymentCycle'
          },
        ]
      }
    }
  },
  mounted() {
    this.getDetail('2-4')
  },
  watch: {
    form: {
      handler(val, oldVal) {
        if (!this.isReset && !this.isEdit) {
          this.updateEditStatus(1)
        } else if (this.isReset) {
          this.isReset = false;
          this.updateEditStatus(0)
        } else if (this.isEdit) {
          this.isEdit = false;
          this.updateEditStatus(2)
        }
      },
      deep: true
    }
  },
  methods: {
    getDetail(step) {
      const data = {
        organize_id: this.$route.query.id,
        first_level: step.split('-')[0],
        second_level: step.split('-')[1]
      }
      this.loading = true;
      this.$api.dataReportingDetail(data).then(res => {
        this.loading = false;
        this.id = res.data.id || null;
        this.isReset = true;
        this.loading = false;
        if (res.data.content) {
          this.form = JSON.parse(res.data.content)
        }
      })
    },
    save() {
      const params = {
        id: this.id,
        organize_id: this.$route.query.id,
        first_level: 2,
        second_level: 4,
        content: JSON.stringify(this.form)
      }
      console.log(this.form, 'form=========')
      this.$api.saveDataPark(params).then(res => {
        this.id = res.data.id;
        this.isEdit = true;
        this.form = JSON.parse(JSON.parse(res.data.content));
        this.$message.success('保存成功')
        this.updateEditStatus(2)
      })
    },
    handleNumericInput(obj, prop, event) {
      if (obj && prop in obj) {
        obj[prop] = event.replace(/[^\d]/g, '');
      }
    },
    // 重置表单
    resetForm() {
      this.$confirm('确定清空当前页面的数据内容吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.form = JSON.parse(JSON.stringify(DEFAULT_FORM))
        this.isReset = true
      })
    }
  }
}
</script>

<template>
  <div class="overview-park pt-10 pb-20" v-loading="loading">
    <div class="item pt-20 pb-20">
      <h3 class="headline">一、产业痛点</h3>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">1、统计数据</div>
        <div class="content">
          <div class="item-row mb-16">
            <span class="label">痛点数量</span>
            <el-input v-model="form.cytd.tjsj.tdsl" placeholder="请输入"
                      @input="event => handleNumericInput(form.cytd.tjsj, 'tdsl', event)" style="width: 340px;"></el-input>
            <span class="unit">个</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">解决方案</span>
            <el-input v-model="form.cytd.tjsj.jjfa" placeholder="请输入"
                      @input="event => handleNumericInput(form.cytd.tjsj, 'jjfa', event)" style="width: 340px;"></el-input>
            <span class="unit">个</span>
          </div>
        </div>
      </div>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">2、痛点关键词分布<span class="tips">（建议不超过7个关键词）</span></div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.cytd.tdgjcfb" :columns="tableColumns.tdgjcfb">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'industry'">
                  <div class="row-title pointer clearfix" v-if="scope.row.industry !== '' && !scope.row.$isFocused" @dblclick="$set(scope.row, '$isFocused', true)">
                    {{ scope.row.industry }} <i class="fr icon-delete icon17 pointer"
                                                @click="form.cytd.tdgjcfb.splice(scope.$index, 1)"></i></div>
                  <el-input v-else v-model="scope.row.industry" placeholder="请输入行业名称"
                            @focus="$set(scope.row, '$isFocused', true)" @blur="$set(scope.row, '$isFocused', false)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'num'">
                  <el-input v-model="scope.row.num" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'num', event)"></el-input>
                </template>
              </template>
              <div class="table-add" slot="suffix">
                <i class="icon-add icon17 pointer"
                   @click="form.cytd.tdgjcfb.push({industry: '', num: null})"></i>
              </div>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">二、产业核心数据</h3>
      <div class="item-content pl-25">
        <div class="content col-2 pt-20">
          <div class="item-row mb-16">
            <span class="label">产业关键词</span>
            <el-input v-model="form.cyhxsj.cygjc" placeholder="请输入关键词"
                      @input="event => handleNumericInput(form.cyhxsj, 'cygjc', event)" style="width: 340px;"></el-input>
            <span class="unit">个</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">纳税总额</span>
            <el-input v-model="form.cyhxsj.nsze" placeholder="请输入"
                      @input="event => handleNumericInput(form.cyhxsj, 'nsze', event)" style="width: 340px;"></el-input>
            <span class="unit">亿元</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">企业总数</span>
            <el-input v-model="form.cyhxsj.qyzs" placeholder="请输入"
                      @input="event => handleNumericInput(form.cyhxsj, 'qyzs', event)" style="width: 340px;"></el-input>
            <span class="unit">家</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">采购总额</span>
            <el-input v-model="form.cyhxsj.cgze" placeholder="请输入"
                      @input="event => handleNumericInput(form.cyhxsj, 'cgze', event)" style="width: 340px;"></el-input>
            <span class="unit">亿元</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">产值总额</span>
            <el-input v-model="form.cyhxsj.czze" placeholder="请输入年份"
                      @input="event => handleNumericInput(form.cyhxsj, 'czze', event)" style="width: 340px;"></el-input>
            <span class="unit">亿元</span>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">三、产业上游</h3>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">1、主要采购产品<span class="tips">（建议添加8-15个产品）</span></div>
        <div class="content">
          <div class="item-row mb-16">
            <edit-tag placeholder="请输入产品名称（不超过6个字）" :input-maxlength="6" v-model="form.cysy.zycgcp" input-width="300px"></edit-tag>
          </div>
        </div>
      </div>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">2、物流方式占比<span class="tips">（建议不超过5个类型）</span></div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.cysy.wlfszb" :columns="tableColumns.swlfszb">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'industry'">
                  <div class="row-title pointer clearfix" v-if="scope.row.industry !== '' && !scope.row.$isFocused" @dblclick="$set(scope.row, '$isFocused', true)">
                    {{ scope.row.industry }} <i class="fr icon-delete icon17 pointer"
                                                @click="form.cysy.wlfszb.splice(scope.$index, 1)"></i></div>
                  <el-input v-else v-model="scope.row.industry" placeholder="请输入行业名称"
                            @focus="$set(scope.row, '$isFocused', true)" @blur="$set(scope.row, '$isFocused', false)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'proportion'">
                  <el-input v-model="scope.row.proportion" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'proportion', event)"></el-input>
                </template>
              </template>
              <div class="table-add" slot="suffix">
                <i class="icon-add icon17 pointer"
                   @click="form.cysy.wlfszb.push({industry: '', proportion: null})"></i>
              </div>
            </horizontal-table>
          </div>
        </div>
      </div>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">3、物流成本<span class="tips">（建议不超过6个月份）</span></div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.cysy.wlcb" :columns="tableColumns.swlcb">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'industry'">
                  <div class="row-title pointer clearfix" v-if="scope.row.industry !== '' && !scope.row.$isFocused" @dblclick="$set(scope.row, '$isFocused', true)">
                    {{ scope.row.industry }} <i class="fr icon-delete icon17 pointer"
                                                @click="form.cysy.wlcb.splice(scope.$index, 1)"></i></div>
                  <el-input v-else v-model="scope.row.industry" placeholder="请输入行业名称"
                            @focus="$set(scope.row, '$isFocused', true)" @blur="$set(scope.row, '$isFocused', false)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'amount'">
                  <el-input v-model="scope.row.amount" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'amount', event)"></el-input>
                </template>
              </template>
              <div class="table-add" slot="suffix">
                <i class="icon-add icon17 pointer"
                   @click="form.cysy.wlcb.push({industry: '', proportion: null})"></i>
              </div>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">四、产业下游</h3>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">1、主要销售产品<span class="tips">（建议添加8-15个产品）</span></div>
        <div class="content">
          <div class="item-row mb-16">
            <edit-tag placeholder="请输入产品名称（不超过6个字）" :input-maxlength="6" v-model="form.cyxy.zyxscp" input-width="300px"></edit-tag>
          </div>
        </div>
      </div>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">2、物流方式占比<span class="tips">（建议不超过5个类型）</span></div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.cyxy.wlfszb" :columns="tableColumns.xwlfszb">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'industry'">
                  <div class="row-title pointer clearfix" v-if="scope.row.industry !== '' && !scope.row.$isFocused" @dblclick="$set(scope.row, '$isFocused', true)">
                    {{ scope.row.industry }} <i class="fr icon-delete icon17 pointer"
                                                @click="form.cyxy.wlfszb.splice(scope.$index, 1)"></i></div>
                  <el-input v-else v-model="scope.row.industry" placeholder="请输入行业名称"
                            @focus="$set(scope.row, '$isFocused', true)" @blur="$set(scope.row, '$isFocused', false)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'proportion'">
                  <el-input v-model="scope.row.proportion" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'proportion', event)"></el-input>
                </template>
              </template>
              <div class="table-add" slot="suffix">
                <i class="icon-add icon17 pointer"
                   @click="form.cyxy.wlfszb.push({industry: '', proportion: null})"></i>
              </div>
            </horizontal-table>
          </div>
        </div>
      </div>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">3、物流成本<span class="tips">（建议不超过6个月份）</span></div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.cyxy.wlcb" :columns="tableColumns.xwlcb">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'industry'">
                  <div class="row-title pointer clearfix" v-if="scope.row.industry !== '' && !scope.row.$isFocused" @dblclick="$set(scope.row, '$isFocused', true)">
                    {{ scope.row.industry }} <i class="fr icon-delete icon17 pointer"
                                                @click="form.cyxy.wlcb.splice(scope.$index, 1)"></i></div>
                  <el-input v-else v-model="scope.row.industry" placeholder="请输入行业名称"
                            @focus="$set(scope.row, '$isFocused', true)" @blur="$set(scope.row, '$isFocused', false)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'amount'">
                  <el-input v-model="scope.row.amount" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'amount', event)"></el-input>
                </template>
              </template>
              <div class="table-add" slot="suffix">
                <i class="icon-add icon17 pointer"
                   @click="form.cyxy.wlcb.push({industry: '', proportion: null})"></i>
              </div>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">五、融资需求</h3>
      <div class="item-content pl-25">
        <div class="subtitle mb-10"></div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.rzxq" :columns="tableColumns.rzxq" cell-width="120px" :cell-auto-width="false" header-width="152px">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'month'">
                  <div class="row-title">
                    {{ scope.row.month }}
                  </div>
                </template>
                <template v-else-if="scope.prop === 'demandAmount'">
                  <el-input v-model="scope.row.demandAmount" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'demandAmount', event)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'repaymentCycle'">
                  <el-input v-model="scope.row.repaymentCycle" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'repaymentCycle', event)"></el-input>
                </template>
              </template>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pb-20 pl-25 btn_box">
      <span class="btn blue" @click="save">确定</span>
      <span class="btn" @click="resetForm">重置</span>
    </div>
  </div>
</template>

<style lang="scss" scoped src="@/styles/common.scss"></style>
<style scoped lang="scss">
.overview-park {
  > .item {
    border-bottom: 1px solid #EDF1F4;

    &:nth-last-child(-n+3) {
      border-bottom: none;
    }
  }
}

.headline {
  font-weight: bold;
  font-size: 14px;
  color: #242D33;
  line-height: 36px;
}

.subtitle, .label, .unit {
  font-weight: 400;
  font-size: 14px;
  color: #242D33;
  line-height: 36px;

  .tips {
    color: #6C7880;
  }
}

.label {
  font-size: 12px;
  margin-right: 12px;
  display: inline-block;
  width: 74px;
  text-align: right;
}

.unit {
  font-size: 12px;
  margin-left: 12px;
}

.row-title {
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 20px;

  .icon-delete {
    position: absolute;
    right: 0;
  }

  &.center {
    text-align: center;
    justify-content: center;
    padding-right: 0;
  }
}

.add-btn {
  display: inline-flex;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border: 1px solid #3984F5;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #3984F5;
  cursor: pointer;
  padding: 0 16px;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  &:hover {
    background-color: #3984F5;
    color: #fff;

    .icon-add {
      background-image: url("~@/assets/icon/add_white_icon@2x.png");
    }
  }
}

.row-year {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-delete {
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-add {
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.col-2 {
  display: grid;
  grid-template-columns: 500px minmax(500px, 1fr);
  grid-column-gap: 20px;
}

.btn_box {
  margin-top: 0;
}
</style>