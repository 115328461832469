<script>
import HorizontalTable
  from "@/views/system/organization/dataReporting/components/common/horizontal-table/HorizontalTable.vue";

const DEFAULT_FORM = {
  zsxmtj: {
    tjsj: {
      ljqyxm: null,
      ljtzje: null,
      ljdwzj: null
    },
    jezb: [
      {
        industry: '精致茶制业',
        investmentAmount: null,
        inPlaceFunds: null
      },
      {
        industry: '精致咖啡制业',
        investmentAmount: null,
        inPlaceFunds: null
      },
      {
        industry: '绿色食品',
        investmentAmount: null,
        inPlaceFunds: null
      },
      {
        industry: '酒/饮料',
        investmentAmount: null,
        inPlaceFunds: null
      },
      {
        industry: '其他',
        investmentAmount: null,
        inPlaceFunds: null
      }
    ]
  },
  zyqyqk: {
    gsqy: null,
    gxqy: null,
    kjqy: null,
  },
  tdjsqk: {
    ghzmj: null,
    ybcqz: null,
    yzmj: null,
    zjmj: null,
    ygmj: null,
    jcmj: null,
  },
  yqhyczqk: {
    tjsj: {
      hyzcz: null,
      tbzzl: null,
    },
    czzb: [
      {
        industry: '精致茶制业',
        outputValue: null,
        proportion: null
      },
      {
        industry: '精致咖啡制业',
        outputValue: null,
        proportion: null
      },
      {
        industry: '绿色食品',
        outputValue: null,
        proportion: null
      },
      {
        industry: '酒/饮料',
        outputValue: null,
        proportion: null
      },
      {
        industry: '其他',
        outputValue: null,
        proportion: null
      }
    ]
  },
  jsxmtjqk: [
    {
      type: '已投产',
      numberOfProjects: null
    },
    {
      type: '建设中',
      numberOfProjects: null
    },
    {
      type: '前期手续',
      numberOfProjects: null
    },
    {
      type: '暂未启动',
      numberOfProjects: null
    }
  ],
}

export default {
  name: "overviewPark", // 园区总览
  inject: ["updateEditStatus"], // 注入updateEditStatus编辑状态，用来判断是否提示保存 0：未编辑，1：编辑中，2：已保存方法
  components: {
    HorizontalTable
  },
  data() {
    return {
      id: null,
      loading: false,
      // 是否重置表单
      isReset: false,
      isEdit: false,
      form: JSON.parse(JSON.stringify(DEFAULT_FORM)),
      tableColumns: {
        jezb: [
          {
            label: '金额/行业',
            prop: 'industry'
          },
          {
            label: '累计投资金额（亿）',
            prop: 'investmentAmount'
          },
          {
            label: '累计到位资金（亿）',
            prop: 'inPlaceFunds'
          }
        ],
        czzb: [
          {
            label: '金额/行业',
            prop: 'industry',
          },
          {
            label: '产值（亿）',
            prop: 'outputValue',
          },
          {
            label: '占比（%）',
            prop: 'proportion',
          }
        ],
        jsxmtjqk: [
          {
            label: '数量/类型',
            prop: 'type'
          },
          {
            label: '项目数量（个）',
            prop: 'numberOfProjects'
          }
        ]
      }
    }
  },
  mounted() {
    this.getDetail('1-1')
  },
  watch: {
    form: {
      handler(val, oldVal) {
        if (!this.isReset && !this.isEdit) {
          this.updateEditStatus(1)
        } else if (this.isReset) {
          this.isReset = false;
          this.updateEditStatus(0)
        } else if (this.isEdit) {
          this.isEdit = false;
          this.updateEditStatus(2)
        }
      },
      deep: true
    }
  },
  methods: {
    getDetail(step) {
      const data = {
        organize_id: this.$route.query.id,
        first_level: step.split('-')[0],
        second_level: step.split('-')[1]
      }
      this.loading = true;
      this.$api.dataReportingDetail(data).then(res => {
        this.loading = false;
        this.id = res.data.id || null;
        this.isReset = true;
        this.loading = false;
        if (res.data.content) {
          this.form = JSON.parse(res.data.content)
        }
      })
    },
    save() {
      const params = {
        id: this.id,
        organize_id: this.$route.query.id,
        first_level: 1,
        second_level: 1,
        content: JSON.stringify(this.form)
      }
      this.$api.saveDataPark(params).then(res => {
        this.id = res.data.id;
        this.form = JSON.parse(JSON.parse(res.data.content));
        this.isEdit = true;
        this.$message.success('保存成功')
        this.updateEditStatus(2)
      })
    },
    handleNumericInput(obj, prop, event) {
      if (obj && prop in obj) {
        obj[prop] = event.replace(/[^\d]/g, '');
      }
    },
    // 重置表单
    resetForm() {
      this.$confirm('确定清空当前页面的数据内容吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.form = JSON.parse(JSON.stringify(DEFAULT_FORM))
        this.isReset = true
      })
    }
  }
}
</script>

<template>
  <div class="overview-park pt-10 pb-20" v-loading="loading">
    <div class="item pt-20 pb-20">
      <h3 class="headline">一、招商项目统计</h3>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">1、统计数据</div>
        <div class="content">
          <div class="item-row mb-16">
            <span class="label">累计签约项目</span>
            <el-input v-model="form.zsxmtj.tjsj.ljqyxm" placeholder="请输入"
                      @input="event => handleNumericInput(form.zsxmtj.tjsj, 'ljqyxm', event)"
                      style="width: 340px;"></el-input>
            <span class="unit">亿元</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">累计投资金额</span>
            <el-input v-model="form.zsxmtj.tjsj.ljtzje" placeholder="请输入"
                      @input="event => handleNumericInput(form.zsxmtj.tjsj, 'ljtzje', event)"
                      style="width: 340px;"></el-input>
            <span class="unit">亿元</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">累计到位资金</span>
            <el-input v-model="form.zsxmtj.tjsj.ljdwzj" placeholder="请输入"
                      @input="event => handleNumericInput(form.zsxmtj.tjsj, 'ljdwzj', event)"
                      style="width: 340px;"></el-input>
            <span class="unit">亿元</span>
          </div>
        </div>
      </div>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">2、不同行业的金额占比<span class="tips">（建议不超过7个行业）</span></div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.zsxmtj.jezb" :columns="tableColumns.jezb">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'industry'">
                  <div class="row-title pointer clearfix" v-if="scope.row.industry !== '' && !scope.row.$isFocused"
                       @dblclick="$set(scope.row, '$isFocused', true)">
                    {{ scope.row.industry }} <i class="fr icon-delete icon17 pointer"
                                                @click="form.zsxmtj.jezb.splice(scope.$index, 1)"></i></div>
                  <el-input v-else v-model="scope.row.industry" placeholder="请输入行业名称"
                            @focus="$set(scope.row, '$isFocused', true)"
                            @blur="$set(scope.row, '$isFocused', false)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'investmentAmount'">
                  <el-input v-model="scope.row.investmentAmount" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'investmentAmount', event)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'inPlaceFunds'">
                  <el-input v-model="scope.row.inPlaceFunds" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'inPlaceFunds', event)"></el-input>
                </template>
              </template>
              <div class="table-add" slot="suffix">
                <i class="icon-add icon17 pointer"
                   @click="form.zsxmtj.jezb.push({industry: '', investmentAmount: null, inPlaceFunds: null})"></i>
              </div>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">二、在园企业情况</h3>
      <div class="item-content pl-25">
        <div class="content pt-20">
          <div class="item-row mb-16">
            <span class="label">规上企业</span>
            <el-input v-model="form.zyqyqk.gsqy" placeholder="请输入"
                      @input="event => handleNumericInput(form.zyqyqk, 'gsqy', event)" style="width: 340px;"></el-input>
            <span class="unit">个</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">高新企业</span>
            <el-input v-model="form.zyqyqk.gxqy" placeholder="请输入"
                      @input="event => handleNumericInput(form.zyqyqk, 'gxqy', event)" style="width: 340px;"></el-input>
            <span class="unit">个</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">科技企业</span>
            <el-input v-model="form.zyqyqk.kjqy" placeholder="请输入"
                      @input="event => handleNumericInput(form.zyqyqk, 'kjqy', event)" style="width: 340px;"></el-input>
            <span class="unit">个</span>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">三、土地建设情况</h3>
      <div class="item-content pl-25">
        <div class="content col-2 pt-20">
          <div class="item-row mb-16">
            <span class="label">规划总面积</span>
            <el-input v-model="form.tdjsqk.ghzmj" placeholder="请输入"
                      @input="event => handleNumericInput(form.tdjsqk, 'ghzmj', event)"
                      style="width: 340px;"></el-input>
            <span class="unit">亩</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">已办产权证</span>
            <el-input v-model="form.tdjsqk.ybcqz" placeholder="请输入"
                      @input="event => handleNumericInput(form.tdjsqk, 'ybcqz', event)"
                      style="width: 340px;"></el-input>
            <span class="unit">亩</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">已征面积</span>
            <el-input v-model="form.tdjsqk.yzmj" placeholder="请输入"
                      @input="event => handleNumericInput(form.tdjsqk, 'yzmj', event)" style="width: 340px;"></el-input>
            <span class="unit">亩</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">在建面积</span>
            <el-input v-model="form.tdjsqk.zjmj" placeholder="请输入"
                      @input="event => handleNumericInput(form.tdjsqk, 'zjmj', event)" style="width: 340px;"></el-input>
            <span class="unit">亩</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">已供面积</span>
            <el-input v-model="form.tdjsqk.ygmj" placeholder="请输入"
                      @input="event => handleNumericInput(form.tdjsqk, 'ygmj', event)" style="width: 340px;"></el-input>
            <span class="unit">亩</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">建成面积</span>
            <el-input v-model="form.tdjsqk.jcmj" placeholder="请输入"
                      @input="event => handleNumericInput(form.tdjsqk, 'jcmj', event)" style="width: 340px;"></el-input>
            <span class="unit">亩</span>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">四、园区行业产值情况</h3>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">1、统计数据</div>
        <div class="content">
          <div class="item-row mb-16">
            <span class="label">行业总产值</span>
            <el-input v-model="form.yqhyczqk.tjsj.hyzcz" placeholder="请输入"
                      @input="event => handleNumericInput(form.yqhyczqk.tjsj, 'hyzcz', event)"
                      style="width: 340px;"></el-input>
            <span class="unit">亿元</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">同比增长率</span>
            <el-input v-model="form.yqhyczqk.tjsj.tbzzl" placeholder="请输入"
                      @input="event => handleNumericInput(form.yqhyczqk.tjsj, 'tbzzl', event)"
                      style="width: 340px;"></el-input>
            <span class="unit">%</span>
          </div>
        </div>
      </div>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">2、不同行业的产值占比<span class="tips">（建议不超过7个行业）</span></div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.yqhyczqk.czzb" :columns="tableColumns.czzb">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'industry'">
                  <div class="row-title pointer clearfix" v-if="scope.row.industry !== '' && !scope.row.$isFocused"
                       @dblclick="$set(scope.row, '$isFocused', true)">
                    {{ scope.row.industry }} <i class="fr icon-delete icon17 pointer"
                                                @click="form.yqhyczqk.czzb.splice(scope.$index, 1)"></i></div>
                  <el-input v-else v-model="scope.row.industry" placeholder="请输入行业名称"
                            @focus="$set(scope.row, '$isFocused', true)"
                            @blur="$set(scope.row, '$isFocused', false)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'outputValue'">
                  <el-input v-model="scope.row.outputValue" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'outputValue', event)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'proportion'">
                  <el-input v-model="scope.row.proportion" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'proportion', event)"></el-input>
                </template>
              </template>
              <div class="table-add" slot="suffix">
                <i class="icon-add icon17 pointer"
                   @click="form.yqhyczqk.czzb.push({industry: '', outputValue: null, proportion: null})"></i>
              </div>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">五、建设项目统计情况</h3>
      <div class="item-content pl-25">
        <div class="content pt-20">
          <div class="item-row mb-16">
            <horizontal-table :data="form.jsxmtjqk" :columns="tableColumns.jsxmtjqk">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'type'">
                  <div class="row-title pointer clearfix" v-if="scope.row.type !== '' && !scope.row.$isFocused"
                       @dblclick="$set(scope.row, '$isFocused', true)">
                    {{ scope.row.type }} <i class="fr icon-delete icon17 pointer"
                                            @click="form.jsxmtjqk.splice(scope.$index, 1)"></i></div>
                  <el-input v-else v-model="scope.row.type" placeholder="请输入建设类型"
                            @focus="$set(scope.row, '$isFocused', true)"
                            @blur="$set(scope.row, '$isFocused', false)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'numberOfProjects'">
                  <el-input v-model="scope.row.numberOfProjects" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'numberOfProjects', event)"></el-input>
                </template>
              </template>
              <div class="table-add" slot="suffix">
                <i class="icon-add icon17 pointer"
                   @click="form.jsxmtjqk.push({type: '', numberOfProjects: null})"></i>
              </div>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pb-20 pl-25 btn_box">
      <span class="btn blue" @click="save">确定</span>
      <span class="btn" @click="resetForm">重置</span>
    </div>
  </div>
</template>

<style lang="scss" scoped src="@/styles/common.scss"></style>
<style scoped lang="scss">
.overview-park {
  > .item {
    border-bottom: 1px solid #EDF1F4;

    &:nth-last-child(-n+3) {
      border-bottom: none;
    }
  }
}

.headline {
  font-weight: bold;
  font-size: 14px;
  color: #242D33;
  line-height: 36px;
}

.subtitle, .label, .unit {
  font-weight: 400;
  font-size: 14px;
  color: #242D33;
  line-height: 36px;

  .tips {
    color: #6C7880;
  }
}

.label {
  font-size: 12px;
  margin-right: 12px;
  display: inline-block;
  width: 74px;
  text-align: right;
}

.unit {
  font-size: 12px;
  margin-left: 12px;
}

.row-title {
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 20px;

  .icon-delete {
    position: absolute;
    right: 0;
  }
}

.table-add {
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.col-2 {
  display: grid;
  grid-template-columns: 500px minmax(500px, 1fr);
  grid-column-gap: 20px;
}

.btn_box {
  margin-top: 0;
}
</style>