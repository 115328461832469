<template>
  <div class="horizontal-table"
       :style="{'--header-width': headerWidth, '--cell-width': cellWidth, '--cell-height': cellHeight, '--table-height': tableHeight, '--year-cell-width': yearCellWidth}">
    <div class="table-container" ref="tableContainer">
      <div class="table-body">
        <div class="table-row" v-for="(row, index) in data" :key="index">
          <div class="table-item" ref="tableHeader">
            <template v-for="(column, cIndex) in columns">
              <div class="table-cell table-header" :key="cIndex">
                <slot name="table-header" :column="column" :label="column.label" :cIndex="0" :rIndex="cIndex">
                  {{ column.label }}
                </slot>
              </div>
            </template>
          </div>
          <div class="table-item table-item-year">
            <div class="table-cell table-year-cell" :style="{height: tableItemHeight}">
              <slot name="table-year-cell" :row="row" :$index="index" :cIndex="1" :rIndex="index" :prop="'year'"
                    :$isFocused="false"
                    :value="row.year">{{ row.year }}
              </slot>
            </div>
          </div>
          <div class="table-item" v-for="(item, i) in row.data" :key="i">
            <div class="table-cell" v-for="(column, cIndex) in columns" :key="cIndex">
              <slot name="table-cell" :row="item" :$index="index" :cIndex="index + 2" :rIndex="cIndex"
                    :prop="column.prop"
                    :column="column" :$isFocused="false"
                    :value="item[column.prop]">{{ item[column.prop] }}
              </slot>
            </div>
          </div>
          <div class="row-suffix" :style="{height: tableItemHeight}">
              <slot name="row-suffix" :row="row" :$index="index"></slot>
          </div>
        </div>
      </div>
      <div class="table-suffix">
        <slot name="table-suffix"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorizontalTableYear',
  props: {
    data: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    headerWidth: {
      type: String,
      default: '140px'
    },
    yearCellWidth: {
      type: String,
      default: '110px'
    },
    cellWidth: {
      type: String,
      default: '110px'
    },
    cellHeight: {
      type: String,
      default: '60px'
    },
    tableHeight: {
      type: String,
      default: '200px'
    }
  },
  watch: {
    data: {
      handler(newVal, oldVal) {
        this.numberOfColumns = 1;
        this.$nextTick(() => {
          const tableHeight = this.$refs.tableContainer.getBoundingClientRect().height;
          const tableItemHeight = this.$refs.tableHeader[0].getBoundingClientRect().height;
          this.numberOfColumns = Math.ceil(tableHeight / tableItemHeight);
          this.tableItemHeight = (tableItemHeight + 1) + 'px';
          console.log(tableHeight, tableItemHeight, this.numberOfColumns, 111111111111);
        });
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      numberOfColumns: 1,
      tableItemHeight: ''
    }
  }
}
</script>

<style scoped lang="scss">
.horizontal-table {
  box-sizing: border-box;
  width: 100%;

  .table-container {
    box-sizing: border-box;
    width: 100%;
  }

  .table-body {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .table-row {
      box-sizing: border-box;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .table-item {
        box-sizing: border-box;
        //min-width: var(--cell-width);
        position: relative;
        margin: 0;
      }

      .row-suffix {
        margin: 0 -1px -1px 0; //边框线重合
      }
    }
  }

  .table-cell {
    box-sizing: border-box;
    margin: 0 -1px -1px 0; //边框线重合
    font-weight: 400;
    font-size: 12px;
    color: #444F57;
    line-height: 36px;
    padding: 11.5px 15px;
    border: 1px solid #DBE1E5;
    width: calc(var(--cell-width) + 1px);
    height: var(--cell-height);

    &.table-header {
      background: #F6F7F9;
      width: var(--header-width);
    }

    &.table-year-cell {
      width: calc(var(--year-cell-width) + 1px);
    }
  }
}


/* 其他样式可以根据需要进行添加 */
</style>
