<script>
import HorizontalTable from "@/views/system/organization/dataReporting/components/common/horizontal-table/HorizontalTable.vue";

const DEFAULT_FORM = {
  qy: [
    {
      industry: '精致茶制业',
      below1000W: null,
      between1000WAnd2000W: null,
      over2000W: null
    },
    {
      industry: '精致咖啡制业',
      below1000W: null,
      between1000WAnd2000W: null,
      over2000W: null
    },
    {
      industry: '绿色食品',
      below1000W: null,
      between1000WAnd2000W: null,
      over2000W: null
    },
    {
      industry: '酒/饮料',
      below1000W: null,
      between1000WAnd2000W: null,
      over2000W: null
    },
    {
      industry: '其他',
      below1000W: null,
      between1000WAnd2000W: null,
      over2000W: null
    }
  ],
  tjsj: {
    qyzs: null,
    rzdk: null,
    tds: null,
    cngxs: null,
    xzxqs: null,
    cgzje: null,
    wlfhs: null,
    xszje: null,
  },
  tdk: [
    {
      painPointType: '招人难',
      number: null
    },
    {
      painPointType: '融资困难',
      number: null
    },
    {
      painPointType: '经营成本高',
      number: null
    },
    {
      painPointType: '管理不完善',
      number: null
    },
    {
      painPointType: '营销不足',
      number: null
    },
    {
      painPointType: '其他',
      number: null
    }
  ],
  cg: {
    tjsj: {
      ycl: null,
      bcp: null,
      cp: null
    },
    hyzjzb: [
      {
        industry: '精致茶制业',
        money: null,
        proportion: null
      },
      {
        industry: '精致咖啡制业',
        money: null,
        proportion: null
      },
      {
        industry: '绿色食品',
        money: null,
        proportion: null
      },
      {
        industry: '酒/饮料',
        money: null,
        proportion: null
      },
      {
        industry: '其他',
        money: null,
        proportion: null
      }
    ]
  },
  xs: {
    tjsj: {
      ycl: null,
      bcp: null,
      cp: null
    },
    hyzjzb: [
      {
        industry: '精致茶制业',
        money: null,
        proportion: null
      },
      {
        industry: '精致咖啡制业',
        money: null,
        proportion: null
      },
      {
        industry: '绿色食品',
        money: null,
        proportion: null
      },
      {
        industry: '酒/饮料',
        money: null,
        proportion: null
      },
      {
        industry: '其他',
        money: null,
        proportion: null
      }
    ]
  },
  ccwl: {
    wlcb: [
      {
        month: '1月',
        money: null,
        number: null
      },
      {
        month: '2月',
        money: null,
        number: null
      },
      {
        month: '3月',
        money: null,
        number: null
      },
      {
        month: '4月',
        money: null,
        number: null
      },
      {
        month: '5月',
        money: null,
        number: null
      },
      {
        month: '6月',
        money: null,
        number: null
      },
      {
        month: '7月',
        money: null,
        number: null
      },
      {
        month: '8月',
        money: null,
        number: null
      },
      {
        month: '9月',
        money: null,
        number: null
      },
      {
        month: '10月',
        money: null,
        number: null
      },
      {
        month: '11月',
        money: null,
        number: null
      },
      {
        month: '12月',
        money: null,
        number: null
      }
    ],
    ysfszb: [
      {
        type: '陆运',
        proportion: null
      },
      {
        type: '水运',
        proportion: null
      },
      {
        type: '空运',
        proportion: null
      },
      {
        type: '自建',
        proportion: null
      }
    ]
  },
  dkxqfx: [
    {
      industry: '精致茶制业',
      money: null,
      cycle: null
    },
    {
      industry: '精致咖啡制业',
      money: null,
      cycle: null
    },
    {
      industry: '绿色食品',
      money: null,
      cycle: null
    },
    {
      industry: '酒/饮料',
      money: null,
      cycle: null
    },
    {
      industry: '其他',
      money: null,
      cycle: null
    }
  ],
  scxz: [
    {
      industry: '精致茶制业',
      number: null
    },
    {
      industry: '精致咖啡制业',
      number: null
    },
    {
      industry: '绿色食品',
      number: null
    },
    {
      industry: '酒/饮料',
      number: null
    },
    {
      industry: '其他',
      number: null
    }
  ]
}

export default {
  name: "bigDataPark", // 园区大数据
  inject: ["updateEditStatus"], // 注入updateEditStatus编辑状态，用来判断是否提示保存 0：未编辑，1：编辑中，2：已保存方法
  components: {
    HorizontalTable
  },
  data() {
    return {
      id: null,
      loading: false,
      // 是否重置表单
      isReset: false,
      isEdit: false,
      form: JSON.parse(JSON.stringify(DEFAULT_FORM)),
      tableColumns: {
        qy: [
          {
            label: '产值/行业',
            prop: 'industry'
          },
          {
            label: '1000W以下',
            prop: 'below1000W'
          },
          {
            label: '1000-2000W',
            prop: 'between1000WAnd2000W'
          },
          {
            label: '2000W以上',
            prop: 'over2000W'
          }
        ],
        tdk: [
          {
            label: '数量/痛点类型',
            prop: 'painPointType'
          },
          {
            label: '数量',
            prop: 'number'
          }
        ],
        hyzjzb: [
          {
            label: '金额/行业',
            prop: 'industry'
          },
          {
            label: '需求金额（千万）',
            prop: 'money'
          },
          {
            label: '占比（%）',
            prop: 'proportion'
          }
        ],
        wlcb: [
          {
            label: '成本/月份',
            prop: 'month'
          },
          {
            label: '物流运输成本（千万）',
            prop: 'money'
          },
          {
            label: '发货次数（千次）',
            prop: 'number'
          }
        ],
        ysfszb: [
          {
            label: '占比/运输方式',
            prop: 'type'
          },
          {
            label: '占比（%）',
            prop: 'proportion'
          }
        ],
        dkxqfx: [
          {
            label: '金额/行业',
            prop: 'industry'
          },
          {
            label: '需求金额（千万）',
            prop: 'money'
          },
          {
            label: '贷款周期（月）',
            prop: 'cycle'
          }
        ],
        scxz: [
          {
            label: '数量/行业',
            prop: 'industry'
          },
          {
            label: '数量',
            prop: 'number'
          }
        ]
      }
    }
  },
  watch: {
    form: {
      handler(val, oldVal) {
        if (!this.isReset && !this.isEdit) {
          this.updateEditStatus(1)
        } else if (this.isReset) {
          this.isReset = false;
          this.updateEditStatus(0)
        } else if (this.isEdit) {
          this.isEdit = false;
          this.updateEditStatus(2)
        }
      },
      deep: true
    }
  },
  mounted() {
    this.getDetail('2-1')
  },
  methods: {
    getDetail(step) {
      const data = {
        organize_id: this.$route.query.id,
        first_level: step.split('-')[0],
        second_level: step.split('-')[1]
      }
      this.loading = true;
      this.$api.dataReportingDetail(data).then(res => {
        this.loading = false;
        this.id = res.data.id || null;
        this.isReset = true;
        this.loading = false;
        if (res.data.content) {
          this.form = JSON.parse(res.data.content)
        }
      })
    },
    save() {
      const params = {
        id: this.id,
        organize_id: this.$route.query.id,
        first_level: 2,
        second_level: 1,
        content: JSON.stringify(this.form)
      }
      this.$api.saveDataPark(params).then(res => {
        this.id = res.data.id;
        this.form = JSON.parse(JSON.parse(res.data.content));
        this.isEdit = true;
        this.$message.success('保存成功')
        this.updateEditStatus(2)
      })
    },
    handleNumericInput(obj, prop, event) {
      if (obj && prop in obj) {
        obj[prop] = event.replace(/[^\d]/g, '');
      }
    },
    // 重置表单
    resetForm() {
      this.$confirm('确定清空当前页面的数据内容吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.form = JSON.parse(JSON.stringify(DEFAULT_FORM))
        this.isReset = true
      })
    }
  }
}
</script>

<template>
  <div class="overview-park pt-10 pb-20" v-loading="loading">
    <div class="item pt-20 pb-20">
      <h3 class="headline">一、企业</h3>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">不同行业、不同产值的企业数量<span class="tips">（建议不超过7个行业）</span></div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.qy" :columns="tableColumns.qy">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'industry'">
                  <div class="row-title pointer clearfix" v-if="scope.row.industry !== '' && !scope.row.$isFocused" @dblclick="$set(scope.row, '$isFocused', true)">
                    {{ scope.row.industry }} <i class="fr icon-delete icon17 pointer"
                                                   @click="form.qy.splice(scope.$index, 1)"></i></div>
                  <el-input v-else v-model="scope.row.industry" placeholder="请输入行业名称"
                            @focus="$set(scope.row, '$isFocused', true)" @blur="$set(scope.row, '$isFocused', false)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'below1000W'">
                  <el-input v-model="scope.row.below1000W" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'below1000W', event)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'between1000WAnd2000W'">
                  <el-input v-model="scope.row.between1000WAnd2000W" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'between1000WAnd2000W', event)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'over2000W'">
                  <el-input v-model="scope.row.over2000W" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'over2000W', event)"></el-input>
                </template>
              </template>
              <div class="table-add" slot="suffix">
                <i class="icon-add icon17 pointer"
                   @click="form.qy.push({industry: '', below1000W: null, between1000WAnd2000W: null, over2000W: null})"></i>
              </div>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">二、统计数据</h3>
      <div class="item-content pl-25">
        <div class="content col-2 pt-20">
          <div class="item-row mb-16">
            <span class="label">企业总数</span>
            <el-input v-model="form.tjsj.qyzs" placeholder="请输入"
                      @input="event => handleNumericInput(form.tjsj, 'qyzs', event)" style="width: 340px;"></el-input>
            <span class="unit">家</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">融资贷款</span>
            <el-input v-model="form.tjsj.rzdk" placeholder="请输入"
                      @input="event => handleNumericInput(form.tjsj, 'rzdk', event)" style="width: 340px;"></el-input>
            <span class="unit">万元</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">痛点数</span>
            <el-input v-model="form.tjsj.tds" placeholder="请输入"
                      @input="event => handleNumericInput(form.tjsj, 'tds', event)" style="width: 340px;"></el-input>
            <span class="unit">个</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">产能共享数</span>
            <el-input v-model="form.tjsj.cngxs" placeholder="请输入"
                      @input="event => handleNumericInput(form.tjsj, 'cngxs', event)" style="width: 340px;"></el-input>
            <span class="unit">个</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">协作需求数</span>
            <el-input v-model="form.tjsj.xzxqs" placeholder="请输入"
                      @input="event => handleNumericInput(form.tjsj, 'xzxqs', event)" style="width: 340px;"></el-input>
            <span class="unit">个</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">采购总金额</span>
            <el-input v-model="form.tjsj.cgzje" placeholder="请输入"
                      @input="event => handleNumericInput(form.tjsj, 'cgzje', event)" style="width: 340px;"></el-input>
            <span class="unit">千万</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">物流发货数</span>
            <el-input v-model="form.tjsj.wlfhs" placeholder="请输入"
                      @input="event => handleNumericInput(form.tjsj, 'wlfhs', event)" style="width: 340px;"></el-input>
            <span class="unit">次</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">销售总金额</span>
            <el-input v-model="form.tjsj.xszje" placeholder="请输入"
                      @input="event => handleNumericInput(form.tjsj, 'xszje', event)" style="width: 340px;"></el-input>
            <span class="unit">千万</span>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">三、痛点库</h3>
      <div class="item-content pl-25">
        <div class="content pt-20">
          <div class="item-row mb-16">
            <horizontal-table :data="form.tdk" :columns="tableColumns.tdk">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'painPointType'">
                  <div class="row-title pointer clearfix" v-if="scope.row.painPointType !== '' && !scope.row.$isFocused" @dblclick="$set(scope.row, '$isFocused', true)">
                    {{ scope.row.painPointType }} <i class="fr icon-delete icon17 pointer"
                                                @click="form.tdk.splice(scope.$index, 1)"></i></div>
                  <el-input v-else v-model="scope.row.painPointType" placeholder="请输入痛点类型"
                            @focus="$set(scope.row, '$isFocused', true)" @blur="$set(scope.row, '$isFocused', false)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'number'">
                  <el-input v-model="scope.row.number" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'number', event)"></el-input>
                </template>
              </template>
              <div class="table-add" slot="suffix">
                <i class="icon-add icon17 pointer"
                   @click="form.tdk.push({painPointType: '', number: null})"></i>
              </div>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">四、采购</h3>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">1、统计数据</div>
        <div class="content">
          <div class="item-row mb-16">
            <span class="label">原材料</span>
            <el-input v-model="form.cg.tjsj.ycl" placeholder="请输入"
                      @input="event => handleNumericInput(form.cg.tjsj, 'ycl', event)" style="width: 340px;"></el-input>
            <span class="unit">千万</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">半成品</span>
            <el-input v-model="form.cg.tjsj.bcp" placeholder="请输入"
                      @input="event => handleNumericInput(form.cg.tjsj, 'bcp', event)" style="width: 340px;"></el-input>
            <span class="unit">千万</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">成品</span>
            <el-input v-model="form.cg.tjsj.cp" placeholder="请输入"
                      @input="event => handleNumericInput(form.cg.tjsj, 'cp', event)" style="width: 340px;"></el-input>
            <span class="unit">千万</span>
          </div>
        </div>
      </div>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">2、采购行业资金占比<span class="tips">（建议不超过7个行业）</span></div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.cg.hyzjzb" :columns="tableColumns.hyzjzb">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'industry'">
                  <div class="row-title pointer clearfix" v-if="scope.row.industry !== '' && !scope.row.$isFocused" @dblclick="$set(scope.row, '$isFocused', true)">
                    {{ scope.row.industry }} <i class="fr icon-delete icon17 pointer"
                                                @click="form.cg.hyzjzb.splice(scope.$index, 1)"></i></div>
                  <el-input v-else v-model="scope.row.industry" placeholder="请输入行业名称"
                            @focus="$set(scope.row, '$isFocused', true)" @blur="$set(scope.row, '$isFocused', false)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'money'">
                  <el-input v-model="scope.row.money" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'money', event)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'proportion'">
                  <el-input v-model="scope.row.proportion" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'proportion', event)"></el-input>
                </template>
              </template>
              <div class="table-add" slot="suffix">
                <i class="icon-add icon17 pointer"
                   @click="form.cg.hyzjzb.push({industry: '', money: null, proportion: null})"></i>
              </div>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">五、销售</h3>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">1、统计数据</div>
        <div class="content">
          <div class="item-row mb-16">
            <span class="label">原材料</span>
            <el-input v-model="form.xs.tjsj.ycl" placeholder="请输入"
                      @input="event => handleNumericInput(form.xs.tjsj, 'ycl', event)" style="width: 340px;"></el-input>
            <span class="unit">千万</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">半成品</span>
            <el-input v-model="form.xs.tjsj.bcp" placeholder="请输入"
                      @input="event => handleNumericInput(form.xs.tjsj, 'bcp', event)" style="width: 340px;"></el-input>
            <span class="unit">千万</span>
          </div>
          <div class="item-row mb-16">
            <span class="label">成品</span>
            <el-input v-model="form.xs.tjsj.cp" placeholder="请输入"
                      @input="event => handleNumericInput(form.xs.tjsj, 'cp', event)" style="width: 340px;"></el-input>
            <span class="unit">千万</span>
          </div>
        </div>
      </div>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">2、销售行业资金占比<span class="tips">（建议不超过7个行业）</span></div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.xs.hyzjzb" :columns="tableColumns.hyzjzb">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'industry'">
                  <div class="row-title pointer clearfix" v-if="scope.row.industry !== '' && !scope.row.$isFocused" @dblclick="$set(scope.row, '$isFocused', true)">
                    {{ scope.row.industry }} <i class="fr icon-delete icon17 pointer"
                                                @click="form.xs.hyzjzb.splice(scope.$index, 1)"></i></div>
                  <el-input v-else v-model="scope.row.industry" placeholder="请输入行业名称"
                            @focus="$set(scope.row, '$isFocused', true)" @blur="$set(scope.row, '$isFocused', false)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'money'">
                  <el-input v-model="scope.row.money" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'money', event)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'proportion'">
                  <el-input v-model="scope.row.proportion" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'proportion', event)"></el-input>
                </template>
              </template>
              <div class="table-add" slot="suffix">
                <i class="icon-add icon17 pointer"
                   @click="form.xs.hyzjzb.push({industry: '', money: null, proportion: null})"></i>
              </div>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">六、仓储物流</h3>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">1、物流成本</div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.ccwl.wlcb" :columns="tableColumns.wlcb" cell-width="120px" :cell-auto-width="false" header-width="152px">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'month'">
                  <div class="row-title">
                    {{ scope.row.month }}
                  </div>
                </template>
                <template v-else-if="scope.prop === 'money'">
                  <el-input v-model="scope.row.money" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'money', event)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'number'">
                  <el-input v-model="scope.row.number" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'number', event)"></el-input>
                </template>
              </template>
            </horizontal-table>
          </div>
        </div>
      </div>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">2、不同运输方式的占比<span class="tips">（建议不超过5种方式）</span></div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.ccwl.ysfszb" :columns="tableColumns.ysfszb">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'type'">
                  <div class="row-title pointer clearfix" v-if="scope.row.type !== '' && !scope.row.$isFocused" @dblclick="$set(scope.row, '$isFocused', true)">
                    {{ scope.row.type }} <i class="fr icon-delete icon17 pointer"
                                                @click="form.ccwl.ysfszb.splice(scope.$index, 1)"></i></div>
                  <el-input v-else v-model="scope.row.type" placeholder="请输入运输方式"
                            @focus="$set(scope.row, '$isFocused', true)" @blur="$set(scope.row, '$isFocused', false)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'proportion'">
                  <el-input v-model="scope.row.proportion" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'proportion', event)"></el-input>
                </template>
              </template>
              <div class="table-add" slot="suffix">
                <i class="icon-add icon17 pointer"
                   @click="form.ccwl.ysfszb.push({type: '', proportion: null})"></i>
              </div>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">七、贷款需求分析</h3>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">不同行业的贷款需求分析<span class="tips">（建议不超过7个行业）</span></div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.dkxqfx" :columns="tableColumns.dkxqfx">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'industry'">
                  <div class="row-title pointer clearfix" v-if="scope.row.industry !== '' && !scope.row.$isFocused" @dblclick="$set(scope.row, '$isFocused', true)">
                    {{ scope.row.industry }} <i class="fr icon-delete icon17 pointer"
                                                @click="form.dkxqfx.splice(scope.$index, 1)"></i></div>
                  <el-input v-else v-model="scope.row.industry" placeholder="请输入行业名称"
                            @focus="$set(scope.row, '$isFocused', true)" @blur="$set(scope.row, '$isFocused', false)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'money'">
                  <el-input v-model="scope.row.money" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'money', event)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'cycle'">
                  <el-input v-model="scope.row.cycle" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'cycle', event)"></el-input>
                </template>
              </template>
              <div class="table-add" slot="suffix">
                <i class="icon-add icon17 pointer"
                   @click="form.dkxqfx.push({industry: '', money: null, cycle: null})"></i>
              </div>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pt-20 pb-20">
      <h3 class="headline">八、生产协作</h3>
      <div class="item-content pl-25">
        <div class="subtitle mb-10">不同行业的生产协作数量<span class="tips">（建议不超过7个行业）</span></div>
        <div class="content">
          <div class="item-row mb-16">
            <horizontal-table :data="form.scxz" :columns="tableColumns.scxz">
              <template #table-cell="scope">
                <template v-if="scope.prop === 'industry'">
                  <div class="row-title pointer clearfix" v-if="scope.row.industry !== '' && !scope.row.$isFocused" @dblclick="$set(scope.row, '$isFocused', true)">
                    {{ scope.row.industry }} <i class="fr icon-delete icon17 pointer"
                                                @click="form.scxz.splice(scope.$index, 1)"></i></div>
                  <el-input v-else v-model="scope.row.industry" placeholder="请输入行业名称"
                            @focus="$set(scope.row, '$isFocused', true)" @blur="$set(scope.row, '$isFocused', false)"></el-input>
                </template>
                <template v-else-if="scope.prop === 'number'">
                  <el-input v-model="scope.row.number" placeholder="请输入"
                            @input="event => handleNumericInput(scope.row, 'number', event)"></el-input>
                </template>
              </template>
              <div class="table-add" slot="suffix">
                <i class="icon-add icon17 pointer"
                   @click="form.scxz.push({industry: '', number: null})"></i>
              </div>
            </horizontal-table>
          </div>
        </div>
      </div>
    </div>
    <div class="item pb-20 pl-25 btn_box">
      <span class="btn blue" @click="save">确定</span>
      <span class="btn" @click="resetForm">重置</span>
    </div>
  </div>
</template>

<style lang="scss" scoped src="@/styles/common.scss"></style>
<style scoped lang="scss">
.overview-park {
  > .item {
    border-bottom: 1px solid #EDF1F4;

    &:nth-last-child(-n+3) {
      border-bottom: none;
    }
  }
}

.headline {
  font-weight: bold;
  font-size: 14px;
  color: #242D33;
  line-height: 36px;
}

.subtitle, .label, .unit {
  font-weight: 400;
  font-size: 14px;
  color: #242D33;
  line-height: 36px;

  .tips {
    color: #6C7880;
  }
}

.label {
  font-size: 12px;
  margin-right: 12px;
  display: inline-block;
  width: 60px;
  text-align: right;
}

.unit {
  font-size: 12px;
  margin-left: 12px;
}

.row-title {
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 20px;

  .icon-delete {
    position: absolute;
    right: 0;
  }

  &.center {
    text-align: center;
    justify-content: center;
    padding-right: 0;
  }
}

.table-add {
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.col-2 {
  display: grid;
  grid-template-columns: 500px minmax(500px, 1fr);
  grid-column-gap: 20px;
}

.btn_box {
  margin-top: 0;
}
</style>