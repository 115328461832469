<script>
// 导入axios实例
import httpRequest from '@/request/index'

function submit(url, data) {
  return httpRequest({
    url: url,
    type: 'file',
    method: 'POST',
    data,
  })
}

export default {
  name: "upload",
  props: {
    placeholder: {
      type: String,
      default: "请输入内容"
    },
    accept: {
      type: String,
      default: '.xls,.xlsx'
    },
    value: {
      type: Object,
      default: () => {
        return {
          original_file_name: "",
          file_path: "",
          file_url: ""
        }
      }
    },
    fileSize: {
      type: String,
      default: '20MB'
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    //选择文件
    selectClick(e) {
      console.log(e)
      const file = e.target.files;
      console.log(file[0].size, 'size')
      let formData = new FormData();
      formData.append("file", file[0]);

      this.loading = true;
      submit('file/upload_single_file', formData).then(res => {
        this.$emit('input', res.data)
      }).catch(() => {
        this.$message.error('上传失败');
        // this.$emit('input', {
        //   original_file_name: "",
        //   image_path: "",
        //   image_url: ""
        // })
      }).finally(() => {
        this.loading = false;
      })
    },
    handleInputChange(value) {
      if (!value) {
        this.$emit('input', {
          original_file_name: "",
          file_path: "",
          file_url: ""
        })
      }
    }
  },
}
</script>

<template>
  <div class="upload">
    <div class="input">
      <el-input
          style="width: 100%"
          :placeholder="placeholder"
          v-model="value.original_file_name"
          @change="handleInputChange"
          readonly
          clearable>
      </el-input>
      <i class="icon-del-file" v-if="value.original_file_name" @click="value.original_file_name = ''"></i>
    </div>
    <div class="select-btn" v-loading="loading" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
      <span>{{ !value.original_file_name ? '点击上传' : '重新上传' }}</span>
      <input class="file-btn" :accept="accept" type="file" id="file-input" ref="fileInput"
             @change="selectClick"/>
    </div>
  </div>
</template>

<style lang="scss" scoped src="@/styles/common.scss"></style>
<style scoped lang="scss">
.select-btn {
  box-sizing: border-box;
  display: inline-block;
  margin-left: 10px;
  width: 100px;
  height: 34px;
  background: #3984F5;
  border-radius: 4px;
  line-height: 34px;
  text-align: center;
  font-size: 12px;
  color: #FFFFFF;
  position: relative;

  &:hover {
    cursor: pointer;
    background: lighten(#3984F5, 5%);
  }

  .file-btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;

    &:hover {
      cursor: pointer;
    }
  }
}

.input {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  width: 580px;
  height: 34px;

  .icon-del-file {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
</style>